@charset "UTF-8";
/*--------------------------------------------
  setting
--------------------------------------------*/
/*--------------------------------------------
  value
--------------------------------------------*/
/*--------------------------------------------
  MIXIN 等
--------------------------------------------*/
/*--------------------------------------------
  KEYFRAME
--------------------------------------------*/
/*--------------------------------------------
  MIXIN
--------------------------------------------*/
[data-duration="50"] {
  transition-duration: 50ms; }

[data-duration="100"] {
  transition-duration: 100ms; }

[data-duration="150"] {
  transition-duration: 150ms; }

[data-duration="200"] {
  transition-duration: 200ms; }

[data-duration="250"] {
  transition-duration: 250ms; }

[data-duration="300"] {
  transition-duration: 300ms; }

[data-duration="350"] {
  transition-duration: 350ms; }

[data-duration="400"] {
  transition-duration: 400ms; }

[data-duration="450"] {
  transition-duration: 450ms; }

[data-duration="500"] {
  transition-duration: 500ms; }

[data-duration="550"] {
  transition-duration: 550ms; }

[data-duration="600"] {
  transition-duration: 600ms; }

[data-duration="650"] {
  transition-duration: 650ms; }

[data-duration="700"] {
  transition-duration: 700ms; }

[data-duration="750"] {
  transition-duration: 750ms; }

[data-duration="800"] {
  transition-duration: 800ms; }

[data-duration="850"] {
  transition-duration: 850ms; }

[data-duration="900"] {
  transition-duration: 900ms; }

[data-duration="950"] {
  transition-duration: 950ms; }

[data-duration="1000"] {
  transition-duration: 1000ms; }

[data-duration="1050"] {
  transition-duration: 1050ms; }

[data-duration="1100"] {
  transition-duration: 1100ms; }

[data-duration="1150"] {
  transition-duration: 1150ms; }

[data-duration="1200"] {
  transition-duration: 1200ms; }

[data-duration="1250"] {
  transition-duration: 1250ms; }

[data-duration="1300"] {
  transition-duration: 1300ms; }

[data-duration="1350"] {
  transition-duration: 1350ms; }

[data-duration="1400"] {
  transition-duration: 1400ms; }

[data-duration="1450"] {
  transition-duration: 1450ms; }

[data-duration="1500"] {
  transition-duration: 1500ms; }

[data-duration="1550"] {
  transition-duration: 1550ms; }

[data-duration="1600"] {
  transition-duration: 1600ms; }

[data-duration="1650"] {
  transition-duration: 1650ms; }

[data-duration="1700"] {
  transition-duration: 1700ms; }

[data-duration="1750"] {
  transition-duration: 1750ms; }

[data-duration="1800"] {
  transition-duration: 1800ms; }

[data-duration="1850"] {
  transition-duration: 1850ms; }

[data-duration="1900"] {
  transition-duration: 1900ms; }

[data-duration="1950"] {
  transition-duration: 1950ms; }

[data-duration="2000"] {
  transition-duration: 2000ms; }

[data-duration="2050"] {
  transition-duration: 2050ms; }

[data-duration="2100"] {
  transition-duration: 2100ms; }

[data-duration="2150"] {
  transition-duration: 2150ms; }

[data-duration="2200"] {
  transition-duration: 2200ms; }

[data-duration="2250"] {
  transition-duration: 2250ms; }

[data-duration="2300"] {
  transition-duration: 2300ms; }

[data-duration="2350"] {
  transition-duration: 2350ms; }

[data-duration="2400"] {
  transition-duration: 2400ms; }

[data-duration="2450"] {
  transition-duration: 2450ms; }

[data-duration="2500"] {
  transition-duration: 2500ms; }

[data-duration="2550"] {
  transition-duration: 2550ms; }

[data-duration="2600"] {
  transition-duration: 2600ms; }

[data-duration="2650"] {
  transition-duration: 2650ms; }

[data-duration="2700"] {
  transition-duration: 2700ms; }

[data-duration="2750"] {
  transition-duration: 2750ms; }

[data-duration="2800"] {
  transition-duration: 2800ms; }

[data-duration="2850"] {
  transition-duration: 2850ms; }

[data-duration="2900"] {
  transition-duration: 2900ms; }

[data-duration="2950"] {
  transition-duration: 2950ms; }

[data-duration="3000"] {
  transition-duration: 3000ms; }

[data-duration="3050"] {
  transition-duration: 3050ms; }

[data-duration="3100"] {
  transition-duration: 3100ms; }

[data-duration="3150"] {
  transition-duration: 3150ms; }

[data-duration="3200"] {
  transition-duration: 3200ms; }

[data-duration="3250"] {
  transition-duration: 3250ms; }

[data-duration="3300"] {
  transition-duration: 3300ms; }

[data-duration="3350"] {
  transition-duration: 3350ms; }

[data-duration="3400"] {
  transition-duration: 3400ms; }

[data-duration="3450"] {
  transition-duration: 3450ms; }

[data-duration="3500"] {
  transition-duration: 3500ms; }

[data-duration="3550"] {
  transition-duration: 3550ms; }

[data-duration="3600"] {
  transition-duration: 3600ms; }

[data-duration="3650"] {
  transition-duration: 3650ms; }

[data-duration="3700"] {
  transition-duration: 3700ms; }

[data-duration="3750"] {
  transition-duration: 3750ms; }

[data-duration="3800"] {
  transition-duration: 3800ms; }

[data-duration="3850"] {
  transition-duration: 3850ms; }

[data-duration="3900"] {
  transition-duration: 3900ms; }

[data-duration="3950"] {
  transition-duration: 3950ms; }

[data-duration="4000"] {
  transition-duration: 4000ms; }

[data-duration="4050"] {
  transition-duration: 4050ms; }

[data-duration="4100"] {
  transition-duration: 4100ms; }

[data-duration="4150"] {
  transition-duration: 4150ms; }

[data-duration="4200"] {
  transition-duration: 4200ms; }

[data-duration="4250"] {
  transition-duration: 4250ms; }

[data-duration="4300"] {
  transition-duration: 4300ms; }

[data-duration="4350"] {
  transition-duration: 4350ms; }

[data-duration="4400"] {
  transition-duration: 4400ms; }

[data-duration="4450"] {
  transition-duration: 4450ms; }

[data-duration="4500"] {
  transition-duration: 4500ms; }

[data-duration="4550"] {
  transition-duration: 4550ms; }

[data-duration="4600"] {
  transition-duration: 4600ms; }

[data-duration="4650"] {
  transition-duration: 4650ms; }

[data-duration="4700"] {
  transition-duration: 4700ms; }

[data-duration="4750"] {
  transition-duration: 4750ms; }

[data-duration="4800"] {
  transition-duration: 4800ms; }

[data-duration="4850"] {
  transition-duration: 4850ms; }

[data-duration="4900"] {
  transition-duration: 4900ms; }

[data-duration="4950"] {
  transition-duration: 4950ms; }

[data-duration="5000"] {
  transition-duration: 5000ms; }

[data-delay="50"] {
  transition-delay: 50ms; }

[data-delay="100"] {
  transition-delay: 100ms; }

[data-delay="150"] {
  transition-delay: 150ms; }

[data-delay="200"] {
  transition-delay: 200ms; }

[data-delay="250"] {
  transition-delay: 250ms; }

[data-delay="300"] {
  transition-delay: 300ms; }

[data-delay="350"] {
  transition-delay: 350ms; }

[data-delay="400"] {
  transition-delay: 400ms; }

[data-delay="450"] {
  transition-delay: 450ms; }

[data-delay="500"] {
  transition-delay: 500ms; }

[data-delay="550"] {
  transition-delay: 550ms; }

[data-delay="600"] {
  transition-delay: 600ms; }

[data-delay="650"] {
  transition-delay: 650ms; }

[data-delay="700"] {
  transition-delay: 700ms; }

[data-delay="750"] {
  transition-delay: 750ms; }

[data-delay="800"] {
  transition-delay: 800ms; }

[data-delay="850"] {
  transition-delay: 850ms; }

[data-delay="900"] {
  transition-delay: 900ms; }

[data-delay="950"] {
  transition-delay: 950ms; }

[data-delay="1000"] {
  transition-delay: 1000ms; }

[data-delay="1050"] {
  transition-delay: 1050ms; }

[data-delay="1100"] {
  transition-delay: 1100ms; }

[data-delay="1150"] {
  transition-delay: 1150ms; }

[data-delay="1200"] {
  transition-delay: 1200ms; }

[data-delay="1250"] {
  transition-delay: 1250ms; }

[data-delay="1300"] {
  transition-delay: 1300ms; }

[data-delay="1350"] {
  transition-delay: 1350ms; }

[data-delay="1400"] {
  transition-delay: 1400ms; }

[data-delay="1450"] {
  transition-delay: 1450ms; }

[data-delay="1500"] {
  transition-delay: 1500ms; }

[data-delay="1550"] {
  transition-delay: 1550ms; }

[data-delay="1600"] {
  transition-delay: 1600ms; }

[data-delay="1650"] {
  transition-delay: 1650ms; }

[data-delay="1700"] {
  transition-delay: 1700ms; }

[data-delay="1750"] {
  transition-delay: 1750ms; }

[data-delay="1800"] {
  transition-delay: 1800ms; }

[data-delay="1850"] {
  transition-delay: 1850ms; }

[data-delay="1900"] {
  transition-delay: 1900ms; }

[data-delay="1950"] {
  transition-delay: 1950ms; }

[data-delay="2000"] {
  transition-delay: 2000ms; }

[data-delay="2050"] {
  transition-delay: 2050ms; }

[data-delay="2100"] {
  transition-delay: 2100ms; }

[data-delay="2150"] {
  transition-delay: 2150ms; }

[data-delay="2200"] {
  transition-delay: 2200ms; }

[data-delay="2250"] {
  transition-delay: 2250ms; }

[data-delay="2300"] {
  transition-delay: 2300ms; }

[data-delay="2350"] {
  transition-delay: 2350ms; }

[data-delay="2400"] {
  transition-delay: 2400ms; }

[data-delay="2450"] {
  transition-delay: 2450ms; }

[data-delay="2500"] {
  transition-delay: 2500ms; }

[data-delay="2550"] {
  transition-delay: 2550ms; }

[data-delay="2600"] {
  transition-delay: 2600ms; }

[data-delay="2650"] {
  transition-delay: 2650ms; }

[data-delay="2700"] {
  transition-delay: 2700ms; }

[data-delay="2750"] {
  transition-delay: 2750ms; }

[data-delay="2800"] {
  transition-delay: 2800ms; }

[data-delay="2850"] {
  transition-delay: 2850ms; }

[data-delay="2900"] {
  transition-delay: 2900ms; }

[data-delay="2950"] {
  transition-delay: 2950ms; }

[data-delay="3000"] {
  transition-delay: 3000ms; }

[data-delay="3050"] {
  transition-delay: 3050ms; }

[data-delay="3100"] {
  transition-delay: 3100ms; }

[data-delay="3150"] {
  transition-delay: 3150ms; }

[data-delay="3200"] {
  transition-delay: 3200ms; }

[data-delay="3250"] {
  transition-delay: 3250ms; }

[data-delay="3300"] {
  transition-delay: 3300ms; }

[data-delay="3350"] {
  transition-delay: 3350ms; }

[data-delay="3400"] {
  transition-delay: 3400ms; }

[data-delay="3450"] {
  transition-delay: 3450ms; }

[data-delay="3500"] {
  transition-delay: 3500ms; }

[data-delay="3550"] {
  transition-delay: 3550ms; }

[data-delay="3600"] {
  transition-delay: 3600ms; }

[data-delay="3650"] {
  transition-delay: 3650ms; }

[data-delay="3700"] {
  transition-delay: 3700ms; }

[data-delay="3750"] {
  transition-delay: 3750ms; }

[data-delay="3800"] {
  transition-delay: 3800ms; }

[data-delay="3850"] {
  transition-delay: 3850ms; }

[data-delay="3900"] {
  transition-delay: 3900ms; }

[data-delay="3950"] {
  transition-delay: 3950ms; }

[data-delay="4000"] {
  transition-delay: 4000ms; }

[data-delay="4050"] {
  transition-delay: 4050ms; }

[data-delay="4100"] {
  transition-delay: 4100ms; }

[data-delay="4150"] {
  transition-delay: 4150ms; }

[data-delay="4200"] {
  transition-delay: 4200ms; }

[data-delay="4250"] {
  transition-delay: 4250ms; }

[data-delay="4300"] {
  transition-delay: 4300ms; }

[data-delay="4350"] {
  transition-delay: 4350ms; }

[data-delay="4400"] {
  transition-delay: 4400ms; }

[data-delay="4450"] {
  transition-delay: 4450ms; }

[data-delay="4500"] {
  transition-delay: 4500ms; }

[data-delay="4550"] {
  transition-delay: 4550ms; }

[data-delay="4600"] {
  transition-delay: 4600ms; }

[data-delay="4650"] {
  transition-delay: 4650ms; }

[data-delay="4700"] {
  transition-delay: 4700ms; }

[data-delay="4750"] {
  transition-delay: 4750ms; }

[data-delay="4800"] {
  transition-delay: 4800ms; }

[data-delay="4850"] {
  transition-delay: 4850ms; }

[data-delay="4900"] {
  transition-delay: 4900ms; }

[data-delay="4950"] {
  transition-delay: 4950ms; }

[data-delay="5000"] {
  transition-delay: 5000ms; }

/*--------------------------------------------
  BASE
--------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

img {
  vertical-align: top; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

ul li,
ol li {
  list-style-type: none; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

/*--------------------------------------------
  BASE
--------------------------------------------*/
@-ms-viewport {
  width: auto;
  initial-scale: 1; }

html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  @media screen and (max-width: 1199px) {
    html {
      font-size: .8vw; } }
  @media screen and (max-width: 991px) {
    html {
      font-size: 1.3vw; } }
  @media screen and (max-width: 767px) {
    html {
      font-size: 1.75vw; } }
  @media screen and (max-width: 575px) {
    html {
      font-size: 2.5vw; } }

body {
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #111111;
  line-height: 1.875;
  -webkit-font-smoothing: antialiased; }
  @media screen and (max-width: 767px) {
    body {
      line-height: 1.6; } }

.grecaptcha-badge {
  z-index: 50; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

main {
  display: block; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

input:not([type="radio"]):not([type="checkbox"]),
select,
textarea {
  width: 100%;
  font-size: 1.6rem;
  border: solid 1px #cccccc;
  line-height: 1.3;
  display: inline-block;
  padding: .5em .65em; }
  input:not([type="radio"]):not([type="checkbox"]):focus,
  select:focus,
  textarea:focus {
    outline: none; }
  @media screen and (max-width: 1199px) {
    input:not([type="radio"]):not([type="checkbox"]),
    select,
    textarea {
      padding: .75em 1em; } }

::placeholder {
  color: #cccccc; }

select {
  padding: .75em 1.5em .75em .75em; }
  .ios select {
    -webkit-appearance: button;
    appearance: button; }

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
textarea {
  line-height: 1;
  vertical-align: middle; }
  input[type="text"]:fucus,
  input[type="number"]:fucus,
  input[type="password"]:fucus,
  input[type="email"]:fucus,
  input[type="tel"]:fucus,
  input[type="url"]:fucus,
  input[type="search"]:fucus,
  textarea:fucus {
    outline: none;
    background: #d4ebf7; }

textarea {
  min-height: 12.5em; }
  @media screen and (max-width: 767px) {
    textarea {
      min-height: 10em; } }

img {
  vertical-align: middle; }

table {
  width: 100%; }

a {
  color: #333;
  text-decoration: none; }
  a[href=""] {
    pointer-events: none; }
  @media screen and (min-width: 992px) {
    a {
      transition: all 0.6s; } }

svg {
  width: 1em;
  height: 1em;
  fill: #111111; }

h1, h2, h3, h4, h5, h6, th, dt, strong, b {
  font-weight: 500;
  line-height: 1; }

@-ms-viewport {
  width: device-width; }

[role="button"] {
  cursor: pointer; }

.hidden {
  display: none !important; }

.show {
  display: block !important; }

.visible-ss,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-ss-i,
.visible-xs-i,
.visible-sm-i,
.visible-md-i,
.visible-lg-i {
  display: none !important; }

.visible-ss-ib,
.visible-xs-ib,
.visible-sm-ib,
.visible-md-ib,
.visible-lg-ib {
  display: none !important; }

@media screen and (max-width: 575px) {
  .visible-ss {
    display: block !important; }
  table.visible-ss {
    display: table !important; }
  tr.visible-ss {
    display: table-row !important; }
  th.visible-ss,
  td.visible-ss {
    display: table-cell !important; }
  .visible-ss-i {
    display: inline !important; }
  .visible-ss-ib {
    display: inline-block !important; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; }
  .visible-xs-i {
    display: inline !important; }
  .visible-xs-ib {
    display: inline-block !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; }
  .visible-sm-i {
    display: inline !important; }
  .visible-sm-ib {
    display: inline-block !important; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; }
  .visible-md-i {
    display: inline !important; }
  .visible-md-ib {
    display: inline-block !important; } }

@media screen and (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; }
  .visible-lg-i {
    display: inline !important; }
  .visible-lg-ib {
    display: inline-block !important; } }

@media screen and (max-width: 575px) {
  .hidden-ss {
    display: none !important; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .visible-sp {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .visible-tb {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .visible-pc {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-sp {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .hidden-tb {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .hidden-pc {
    display: none !important; } }

/*--------------------------------------------
  plugins
--------------------------------------------*/
/*
SP MENU SAMPLE - HTML BTN + JS
------------------------------

<nav class="slideout-menu" id="js-so_menu">
  <div class="menu-inner">
  </div>
</nav>

<div class="slideout-panel" id="js-so_panel"></div>

<header></header>


<button type="button" class="slideout-hamburger" id="js-so-toggle">
  <span class="sr-only">MENU</span>
  <span class="hamburger-icon"></span>
</button>


// slideout JS
var slideout = new Slideout({
  'panel': document.getElementById('js-so_panel'),
  'menu': document.getElementById('js-so_menu'),
  'duration': 10,
});
var nodeList = document.querySelectorAll('#js-so-toggle, #js-so_panel');
var node = Array.prototype.slice.call(nodeList,0);
node.forEach(function(elem, index){
  elem.addEventListener('click', function() {
    slideout.toggle();
  });
});
*/
.slideout-open,
.slideout-open body {
  overflow: hidden; }

.slideout-menu {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  z-index: 101;
  width: 100%;
  background-color: #333;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1); }

.slideout-open .slideout-menu {
  right: 0; }

.slideout-menu .menu-inner {
  padding: 6em 1.5em; }

.slideout-panel {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  will-change: transform;
  transform: translateX(0) !important;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: none; }

.slideout-open .slideout-panel {
  z-index: 100;
  opacity: 1;
  pointer-events: auto; }

/*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
.slideout-hamburger {
  display: block;
  width: 4.5em;
  height: 100%;
  padding: 2em 1em;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: #222;
  cursor: pointer; }

.hamburger-icon {
  position: relative;
  display: block; }

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  width: 100%;
  height: .2rem;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #fff; }

.hamburger-icon::before,
.hamburger-icon::after {
  content: '';
  display: block;
  position: absolute;
  top: -.6em;
  left: 0; }

.hamburger-icon::after {
  top: .6em; }

.slideout-open .hamburger-icon {
  background-color: transparent; }

.slideout-open .hamburger-icon::before,
.slideout-open .hamburger-icon::after {
  top: 0; }

.slideout-open .hamburger-icon::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.slideout-open .hamburger-icon::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/plugins/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*--------------------------------------------
  module
--------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?fsx2ee");
  src: url("../fonts/icomoon.eot?fsx2ee#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?fsx2ee") format("truetype"), url("../fonts/icomoon.woff?fsx2ee") format("woff"), url("../fonts/icomoon.svg?fsx2ee#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: 1200px;
  max-width: 94%;
  margin-right: auto;
  margin-left: auto; }
  .c-inner.-full {
    width: 94%; }

.c-key_under {
  position: relative; }
  @media screen and (max-width: 991px) {
    .c-key_under__img.c-img_ofi {
      padding-top: 30rem; }
    .c-key_under__text .title {
      padding: 1em 0;
      font-size: 3rem; } }
  @media screen and (max-width: 575px) {
    .c-key_under__img.c-img_ofi {
      padding-top: 25rem; } }
  .c-key_under__img.c-img_ofi {
    padding-top: 35rem; }
  .c-key_under__text {
    background: #111111;
    color: #ffffff; }
    .c-key_under__text .title {
      padding: 1.275em 0;
      font-weight: 500;
      font-size: 3.6rem; }
      @media screen and (min-width: 576px) {
        .c-key_under__text .title {
          display: flex;
          justify-content: flex-start;
          align-items: center; }
          .c-key_under__text .title span {
            position: relative;
            margin-top: 0;
            margin-left: 2em;
            padding-left: 3em; }
            .c-key_under__text .title span:after {
              position: absolute;
              bottom: .5em;
              left: 0;
              width: 2em;
              height: 1px;
              background: #ffffff;
              content: ""; } }
      .c-key_under__text .title span {
        display: block;
        margin-top: .75em;
        font-size: .5em; }
        .c-key_under__text .title span:before {
          content: "カテゴリ："; }

@media screen and (min-width: 576px) {
  .c-page_lead {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .c-page_lead__img {
      width: 40%; }
      .c-page_lead__img img {
        max-width: 100%;
        height: auto; }
    .c-page_lead__text {
      width: 55%; }
      .c-page_lead__text.-mb p {
        margin-bottom: 1.5em; } }

@media screen and (max-width: 575px) {
  .c-page_lead__text {
    margin-top: 2rem; } }

.c-page_lead a {
  font-weight: 600;
  text-decoration: underline; }
  .c-page_lead a:hover {
    text-decoration: none; }

.c-page_lead__img img {
  max-width: 100%;
  height: auto; }

.c-page_lead.-en .c-btnwrap {
  margin-bottom: 1.5em;
  margin-left: 0; }

/*--------------------------------------------
  LISt
--------------------------------------------*/
.c-list.-dot {
  padding-left: 1.5em; }
  .c-list.-dot li {
    list-style: disc; }

.c-list li {
  line-height: 1.5; }
  .c-list li:nth-child(n+2) {
    margin-top: .5em; }

/*--------------------------------------------
  CARD
--------------------------------------------*/
@media screen and (min-width: 768px) {
  .c-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch; } }

@media screen and (min-width: 768px) {
  .c-card li {
    width: 30%;
    margin-right: 5%; }
    .c-card li:nth-child(3n) {
      margin-right: 0; }
    .c-card li:nth-child(n+4) {
      margin-top: 2em; } }

@media screen and (max-width: 767px) {
  .c-card li:nth-child(n+2) {
    margin-top: 2em; } }

.c-card li em {
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em; }

.c-card_img {
  margin-bottom: 1em;
  overflow: hidden;
  border: 1px solid #dddddd; }
  @media screen and (max-width: 767px) {
    .c-card_img {
      margin-bottom: .5em; } }
  .c-card_img img {
    width: 100%;
    transition-duration: .3s; }

.c-card a {
  display: block; }
  @media screen and (min-width: 992px) {
    .c-card a:hover .c-card_img img {
      transform: scale(1.1); } }

/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-ttl_pl {
  margin-bottom: 1.4em;
  font-size: 3rem; }
  @media screen and (max-width: 991px) {
    .c-ttl_pl {
      margin-bottom: 1em;
      font-size: 2.6rem; } }

.c-ttl_sup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 1.7em;
  font-size: 3rem;
  line-height: 1; }
  @media screen and (max-width: 991px) {
    .c-ttl_sup {
      margin-bottom: 1.25em;
      font-size: 2.6rem; } }
  @media screen and (max-width: 575px) {
    .c-ttl_sup {
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 2.4rem; }
      .c-ttl_sup > * {
        width: 100%; }
      .c-ttl_sup .ttl {
        padding-left: 0; }
        .c-ttl_sup .ttl:before, .c-ttl_sup .ttl:after {
          display: none; }
      .c-ttl_sup .sup {
        margin-bottom: 1em;
        margin-left: 0;
        padding-left: 3em; }
        .c-ttl_sup .sup:before {
          top: 50%;
          transform: rotate(0);
          width: 2em;
          height: 1px;
          background: #111111;
          content: ""; }
        .c-ttl_sup .sup:after {
          position: absolute;
          top: calc(50% + 1px);
          left: 0;
          transform: translateY(-50%);
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #111111;
          content: ""; }
      .c-ttl_sup.-symbol:before {
        display: none; }
      .c-ttl_sup.-symbol .ttl {
        padding-left: 0; }
      .c-ttl_sup.-symbol .sup {
        padding-top: 1em;
        padding-left: 2.5em; }
        .c-ttl_sup.-symbol .sup:before {
          display: none; }
        .c-ttl_sup.-symbol .sup:after {
          top: initial;
          bottom: 0;
          transform: translateY(0%);
          width: 2em;
          height: 2em;
          border-radius: 0;
          background: #f5f5f5; } }
  .c-ttl_sup .ttl {
    position: relative;
    padding-left: 2.5em; }
    .c-ttl_sup .ttl:before {
      position: absolute;
      bottom: 50%;
      left: 0;
      transform: translateY(50%);
      width: .35em;
      height: .35em;
      border-radius: 50%;
      background: #111111;
      content: ""; }
    .c-ttl_sup .ttl:after {
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 1.35em;
      height: 1px;
      background: #111111;
      content: ""; }
  .c-ttl_sup .sup {
    position: relative;
    margin-left: 1em;
    padding-left: 1em;
    font-size: .5em; }
    .c-ttl_sup .sup:before {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(35deg);
      width: 1px;
      height: 100%;
      background: #111111;
      content: ""; }
  .c-ttl_sup.-symbol {
    position: relative;
    padding-top: .5em; }
    .c-ttl_sup.-symbol .ttl {
      z-index: 2;
      padding-left: .5em; }
      .c-ttl_sup.-symbol .ttl:before, .c-ttl_sup.-symbol .ttl:after {
        display: none; }
    .c-ttl_sup.-symbol:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 1em;
      height: 1em;
      background: #f5f5f5;
      content: ""; }

.c-ttl_v {
  position: relative;
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 1; }
  @media screen and (min-width: 992px) {
    .c-ttl_v {
      padding-left: .555em;
      border-left: solid 1px #111111;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl; }
      .c-ttl_v:after {
        top: 100%;
        left: -6px; } }
  @media screen and (max-width: 991px) {
    .c-ttl_v {
      padding-right: 11px;
      padding-bottom: .555em;
      border-bottom: solid 1px #111111; }
      .c-ttl_v:after {
        right: 0;
        bottom: -6px; } }
  .c-ttl_v:after {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #111111;
    content: ""; }

.c-ttl_line {
  margin-bottom: 2em;
  font-size: 1.875em;
  line-height: 1.3; }
  @media screen and (max-width: 991px) {
    .c-ttl_line {
      margin-bottom: 1em;
      font-size: 1.5em; }
      .c-ttl_line span:after {
        bottom: -3px;
        width: 7px;
        height: 7px; } }
  .c-ttl_line span {
    display: inline-block;
    position: relative;
    padding-right: calc(.5em + 11px);
    padding-bottom: .5em;
    border-bottom: solid 1px #111111; }
    .c-ttl_line span:after {
      position: absolute;
      right: 0;
      bottom: -5px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: #111111;
      content: ""; }
  .c-ttl_line p {
    margin-top: .5em;
    font-size: .6em; }
  @media screen and (min-width: 992px) {
    .c-ttl_line.-flex {
      display: flex; }
      .c-ttl_line.-flex span {
        width: 80%; } }
  .c-ttl_line.-flex p {
    width: 16rem; }
    @media screen and (min-width: 992px) {
      .c-ttl_line.-flex p {
        margin-left: 1em; } }
    .c-ttl_line.-flex p a {
      display: block; }
      @media screen and (min-width: 992px) {
        .c-ttl_line.-flex p a:hover {
          opacity: .6; } }
      .c-ttl_line.-flex p a img {
        width: 100%;
        height: auto; }

.c-ttl_wt {
  margin-bottom: .5em;
  font-weight: 600;
  font-size: 1.2em; }

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
@media screen and (max-width: 767px) {
  .c-btnwrap a:nth-child(n+2) {
    margin-top: 1em; } }

.c-btnwrap:not(:first-child) {
  margin-top: 2em; }

.c-btnwrap.-fx {
  display: flex;
  justify-content: center;
  align-items: center; }
  .c-btnwrap.-fx.-justify {
    justify-content: space-between; }
  .c-btnwrap.-fx.-right {
    justify-content: flex-end; }

.c-btnwrap.-center {
  text-align: center; }

.c-btnwrap.-right {
  text-align: right; }

.c-btn_arr {
  display: inline-block;
  position: relative;
  min-width: 300px;
  padding: 1.7em 2em;
  border: solid 1px #111111;
  background: #111111;
  color: #ffffff;
  font-size: .875em;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.6s; }
  @media screen and (min-width: 992px) {
    .c-btn_arr:hover {
      background: #ffffff;
      color: #111111; }
      .c-btn_arr:hover:before, .c-btn_arr:hover:after {
        background: #111111; } }
  .c-btn_arr:before, .c-btn_arr:after {
    position: absolute;
    right: 2em;
    width: 1px;
    height: .4em;
    background: #ffffff;
    content: "";
    transition: all 0.6s; }
  .c-btn_arr:before {
    bottom: 50%;
    transform: rotate(-30deg);
    transform-origin: right bottom; }
  .c-btn_arr:after {
    top: 50%;
    transform: rotate(30deg);
    transform-origin: right top; }

.c-btn_arow {
  display: inline-block;
  position: relative;
  padding-top: .6em;
  padding-right: 3.5em;
  padding-bottom: .6em;
  padding-left: 3.5em;
  border-color: #27acd9;
  background-color: #27acd9;
  color: #ffffff;
  font-size: .9em; }
  @media screen and (min-width: 992px) {
    .c-btn_arow:hover {
      opacity: .6; } }
  .c-btn_arow::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 14px;
    width: 20px;
    height: 20px;
    margin: auto;
    border-radius: 50%;
    background: #ffffff;
    content: ""; }
  .c-btn_arow::after {
    position: absolute;
    top: 50%;
    left: 17px;
    box-sizing: border-box;
    color: #27acd9;
    font-size: 12px;
    content: "▲";
    transition: .3s;
    translate: 0 -50%;
    rotate: -30deg;
    -webkit-box-sizing: border-box;
    -webkit-transition: .3s; }

.blue {
  color: #27acd9; }

.c-read p {
  margin-bottom: 1em; }

.c-read:not(:first-child) {
  margin-top: 4em; }

.c-link_arr {
  position: relative;
  font-size: .875em;
  line-height: 1;
  text-align: right;
  transition: all 0.6s; }
  .c-link_arr a {
    padding-right: 2.5em; }
    @media screen and (min-width: 992px) {
      .c-link_arr a:hover {
        opacity: .5;
        padding-right: 1.5em; } }
    .c-link_arr a:after {
      position: absolute;
      top: .15em;
      right: 0;
      transform: skew(45deg);
      width: 1.285em;
      height: .35em;
      border-right: solid 1px #111111;
      border-bottom: solid 1px #111111;
      content: ""; }

.c-annotation {
  padding: 1.5em;
  border: 1px solid #dddddd; }
  .c-annotation:not(:first-child) {
    margin-top: 3em; }

/*--------------------------------------------
  TABLE
--------------------------------------------*/
@media screen and (max-width: 767px) {
  .c-tbl tr th,
  .c-tbl tr td {
    padding: .5em; } }

.c-tbl tr th,
.c-tbl tr td {
  padding: 1em;
  border: 1px solid #000000; }

.c-tbl tr th {
  background: #000000;
  color: #ffffff; }

.c-tbl tr td a {
  display: inline-block;
  text-decoration: underline; }
  @media screen and (min-width: 992px) {
    .c-tbl tr td a:hover {
      text-decoration: none; } }

@media screen and (max-width: 991px) {
  .c-tbl_responsive__inner {
    overflow-x: auto;
    overflow-y: visible; }
    .c-tbl_responsive__inner table {
      width: auto;
      min-width: 100%; }
      .c-tbl_responsive__inner table th,
      .c-tbl_responsive__inner table td {
        white-space: nowrap; }
    .c-tbl_responsive__inner::-webkit-scrollbar {
      height: .6rem; }
    .c-tbl_responsive__inner::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .c-tbl_responsive__inner::-webkit-scrollbar-thumb {
      background: #239293; }
  .c-tbl_responsive:after {
    display: block;
    margin-top: .5em;
    text-align: center;
    content: "横にスクロールできます"; } }

/*--------------------------------------------
 PARTS - ORGANIZATION
--------------------------------------------*/
.c-org_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .c-org_list {
    justify-content: center; }
    .c-org_list__img {
      padding-top: 75%; }
    .c-org_list li {
      width: 31%;
      margin-right: 3.5%; }
      .c-org_list li:nth-child(3n), .c-org_list li:last-child {
        margin-right: 0; }
      .c-org_list li:nth-child(n+4) {
        margin-top: 3.5%; } }
  @media screen and (max-width: 767px) {
    .c-org_list li {
      width: 48%; }
      .c-org_list li:nth-child(n+3) {
        margin-top: 2rem; }
    .c-org_list__img {
      padding-top: 75%; }
      .c-org_list__img img {
        max-width: 80%;
        max-height: 60%; }
    .c-org_list__text h3 span {
      width: 100%;
      border-right: 0; }
    .c-org_list__text h3 i {
      display: none; } }
  .c-org_list li {
    width: 19%;
    background: #ffffff; }
    .c-org_list li a {
      display: block;
      border: solid 1px #111111; }
      @media screen and (min-width: 992px) {
        .c-org_list li a:hover {
          opacity: .5; } }
  .c-org_list__img {
    position: relative;
    width: 100%;
    padding-top: 100%; }
    .c-org_list__img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 75%;
      height: auto; }
  .c-org_list__text {
    background: #111111;
    color: #ffffff; }
    .c-org_list__text h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative; }
      .c-org_list__text h3 span {
        display: inline-block;
        width: 80%;
        padding: .75em 1.25em .65em;
        border-right: solid 1px #6c6c6c; }
      .c-org_list__text h3 i {
        display: inline-block;
        position: relative;
        width: 20%;
        height: 100%; }
        .c-org_list__text h3 i:before, .c-org_list__text h3 i:after {
          position: absolute;
          left: 50%;
          width: 1px;
          height: .4em;
          background: #ffffff;
          content: ""; }
        .c-org_list__text h3 i:before {
          bottom: 50%;
          transform: rotate(-30deg);
          transform-origin: right bottom; }
        .c-org_list__text h3 i:after {
          top: 50%;
          transform: rotate(30deg);
          transform-origin: right top; }
  .c-org_list.-en {
    align-items: stretch; }
    .c-org_list.-en li {
      overflow: hidden; }
      .c-org_list.-en li a {
        display: block;
        height: 100%; }
    .c-org_list.-en .c-org_list__text {
      height: 100%; }

@media screen and (min-width: 768px) {
  .c-link_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch; } }

.c-link_list li {
  list-style-type: none;
  position: relative;
  border-bottom: #dddddd 1px solid;
  line-height: 1.4; }
  @media screen and (min-width: 768px) {
    .c-link_list li {
      width: 48%; } }
  @media screen and (min-width: 992px) {
    .c-link_list li:before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: #000000;
      content: "";
      transition-duration: .3s; }
    .c-link_list li:hover:before {
      width: 100%; } }

.c-link_list a {
  display: block;
  position: relative;
  height: 100%;
  padding: 1em .1em 1em 2.5em;
  font-weight: 600; }
  .c-link_list a:before {
    position: absolute;
    bottom: 50%;
    left: 0;
    transform: translateY(50%);
    width: .35em;
    height: .35em;
    border-radius: 50%;
    background: #111111;
    content: ""; }
  .c-link_list a:after {
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 1.35em;
    height: 1px;
    background: #111111;
    content: ""; }

/*--------------------------------------------
 PARTS - NEWS & EVENT
--------------------------------------------*/
.c-post_list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em; }
  @media screen and (max-width: 767px) {
    .c-post_list li {
      margin-right: .5em; }
    .c-post_list a {
      font-size: 1.4rem; } }
  .c-post_list li {
    margin-right: 1em;
    margin-bottom: .5em;
    line-height: 1; }
  .c-post_list a {
    display: inline-block;
    padding: .8em 1em;
    border: 1px solid #000000;
    background: #000000;
    color: #ffffff;
    font-size: 1.6rem; }
    @media screen and (min-width: 992px) {
      .c-post_list a:hover {
        background: #ffffff;
        color: #000000; } }

@media screen and (min-width: 768px) {
  .c-post_information.-idx {
    display: flex;
    justify-content: space-between;
    align-items: stretch; }
    .c-post_information.-idx li {
      width: 48%;
      margin-right: 0;
      border-top: solid 1px #111111; } }

@media screen and (max-width: 575px) {
  .c-post_information.-idx li:first-child {
    border-top: solid 1px #111111; } }

.c-post_information li {
  width: 100%;
  border-bottom: solid 1px #e6e6e6; }
  .c-post_information li:first-child {
    border-top: solid 1px #e6e6e6; }
  .c-post_information li a {
    display: block;
    position: relative;
    padding: 1.875em 0 1.45em; }
    @media screen and (min-width: 992px) {
      .c-post_information li a:hover:before {
        height: 100%; } }
    .c-post_information li a > * {
      position: relative;
      z-index: 2; }
    .c-post_information li a time {
      display: block;
      margin-bottom: 1em;
      font-size: .875em;
      line-height: 1; }
    .c-post_information li a .title {
      line-height: 1.875; }
    .c-post_information li a:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 0;
      background: #f5f5f5;
      content: "";
      transition: all 0.6s; }

/*--------------------------------------------
 PARTS - COLUMN
--------------------------------------------*/
.c-term_column {
  margin-bottom: 5rem;
  padding: 3rem;
  background: #f5f5f5; }
  @media screen and (max-width: 575px) {
    .c-term_column {
      padding: 3rem 2rem; }
      .c-term_column__list li {
        padding-top: 0; }
        .c-term_column__list li:not(:last-child) {
          margin-right: 0;
          margin-bottom: 1em; }
        .c-term_column__list li a:before {
          border: solid 2px #111111;
          background: transparent; }
        .c-term_column__list li a:after {
          display: none; }
        .c-term_column__list li a.is-act:before {
          background: #111111; } }
  .c-term_column__title {
    font-size: 1.25em; }
  .c-term_column__list {
    margin-top: 1em;
    line-height: 1; }
    @media screen and (min-width: 768px) {
      .c-term_column__list ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start; } }
    .c-term_column__list li {
      padding-top: .5em; }
      .c-term_column__list li:not(:last-child) {
        margin-right: 1.5em; }
      .c-term_column__list li a {
        display: block;
        position: relative;
        padding-left: 1.55em; }
        @media screen and (min-width: 992px) {
          .c-term_column__list li a:hover:after {
            opacity: 1; } }
        .c-term_column__list li a:before, .c-term_column__list li a:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          content: ""; }
        .c-term_column__list li a:before {
          transform: scale(0.4);
          background: #111111; }
        .c-term_column__list li a:after {
          opacity: 0;
          border: solid 2px #111111;
          transition: all 0.6s; }
        .c-term_column__list li a.is-act {
          pointer-events: none; }
          .c-term_column__list li a.is-act:after {
            opacity: 1; }

@media screen and (min-width: 768px) {
  .c-post_column {
    display: flex;
    justify-content: flex-start;
    align-items: stretch; }
    .c-post_column li {
      width: 31%;
      margin-right: 3.5%; }
      .c-post_column li:nth-child(3n) {
        margin-right: 0; }
      .c-post_column li:nth-child(n+4) {
        margin-top: 2rem; } }

@media screen and (max-width: 767px) and (min-width: 576px) {
  .c-post_column li a {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .c-post_column li:nth-child(n+2) {
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: solid 1px #e0e0e0; }
  .c-post_column__img.c-img_ofi {
    width: 40%;
    padding-top: 30%; }
  .c-post_column__text {
    width: 55%;
    padding-top: 0; } }

@media screen and (max-width: 575px) {
  .c-post_column li:nth-child(n+2) {
    margin-top: 3rem; } }

.c-post_column li a {
  display: block; }
  @media screen and (min-width: 992px) {
    .c-post_column li a:hover .c-post_column__img img {
      opacity: .5;
      transform: scale(1.1); }
    .c-post_column li a:hover .c-post_column__text {
      opacity: .5; } }

.c-post_column__img {
  background: #ffffff; }
  .c-post_column__img.c-img_ofi {
    padding-top: 75%;
    overflow: hidden; }
  .c-post_column__img img {
    max-width: 100%;
    height: auto;
    transition: all 0.6s; }

.c-post_column__text {
  padding-top: 1.875em;
  transition: all 0.6s; }
  .c-post_column__text time {
    display: block;
    margin-bottom: 1em;
    font-size: .875em;
    line-height: 1; }
  .c-post_column__text .title {
    line-height: 1.875; }

/*--------------------------------------------
  PARTS - FORM
--------------------------------------------*/
.c-form_box__sheet {
  margin-bottom: 5rem; }
  @media screen and (max-width: 767px) {
    .c-form_box__sheet tr {
      display: block;
      padding: 1.75em 0; }
    .c-form_box__sheet th,
    .c-form_box__sheet td {
      display: block;
      width: 100%;
      padding: 0; }
    .c-form_box__sheet th {
      margin-bottom: 1.5em;
      line-height: 1; } }
  .c-form_box__sheet tr {
    border-bottom: solid 1px #cccccc; }
    .c-form_box__sheet tr.-top th,
    .c-form_box__sheet tr.-top td {
      vertical-align: top; }
    .c-form_box__sheet tr.-top .c-form_box__label {
      padding-top: .5em; }
  .c-form_box__sheet th,
  .c-form_box__sheet td {
    padding: 2em 1em;
    line-height: 1.6;
    text-align: left;
    vertical-align: middle; }
  .c-form_box__sheet th {
    width: 25%; }
  .c-form_box__sheet td {
    width: 85%; }

.c-form_box__label.-require:after {
  margin-left: .5em;
  color: #111111;
  color: #cc1111;
  content: "【必須】"; }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .c-form_box__label.-require:after {
    display: block;
    margin-left: 0;
    font-size: .875em; } }

.c-form_box.en .c-form_box__label.-require:after {
  content: "[Required]"; }

.c-form_box__error .error {
  color: #cc1111; }

@media screen and (max-width: 767px) {
  .c-form_box__btn.-fx {
    display: block;
    text-align: center; }
  .c-form_box__btn > input.c-btn_arr {
    max-width: 70%; } }

.c-form_box__btn > input.c-btn_arr {
  min-width: 1px;
  max-width: 32%;
  margin: 0 auto;
  padding: 1.7em 0;
  font-size: .875em; }
  @media screen and (max-width: 991px) {
    .c-form_box__btn > input.c-btn_arr {
      padding: 1.5em 0; } }
  .c-form_box__btn > input.c-btn_arr.-cancel {
    border-color: #aaaaaa;
    background: #aaaaaa;
    color: #ffffff; }
    @media screen and (min-width: 992px) {
      .c-form_box__btn > input.c-btn_arr.-cancel {
        margin-left: 3rem; }
        .c-form_box__btn > input.c-btn_arr.-cancel:hover {
          opacity: .5; } }
    @media screen and (max-width: 767px) {
      .c-form_box__btn > input.c-btn_arr.-cancel {
        margin-top: 1.5em; } }
  .c-form_box__btn > input.c-btn_arr:hover, .c-form_box__btn > input.c-btn_arr:focus {
    outline: none; }

.c-form_box__agreement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem; }
  .c-form_box__agreement a {
    text-decoration: underline; }
    @media screen and (min-width: 992px) {
      .c-form_box__agreement a:hover {
        text-decoration: none; } }
  .c-form_box__agreement .c-form_box__input.-check {
    margin-right: .75em;
    padding: 0; }
    .c-form_box__agreement .c-form_box__input.-check .mwform-checkbox-field {
      display: block;
      margin-top: 0; }
      .c-form_box__agreement .c-form_box__input.-check .mwform-checkbox-field:not(:last-child) {
        margin-right: 0; }
    .c-form_box__agreement .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text {
      padding-left: 1.75em;
      font-size: 0; }

@media screen and (min-width: 992px) {
  .c-form_box__input.-short .text_field {
    width: 40%; }
  .c-form_box__input.-middle .text_field {
    width: 60%; }
  .c-form_box__input.-long .text_field {
    width: 80%; } }

.c-form_box__input textarea {
  height: 12em; }

.c-form_box__input.-radio {
  padding: .5em 0;
  line-height: 1; }
  @media screen and (min-width: 576px) {
    .c-form_box__input.-radio {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center; } }
  .c-form_box__input.-radio .mwform-radio-field {
    display: block;
    margin-top: 1em; }
    .c-form_box__input.-radio .mwform-radio-field:not(:last-child) {
      margin-right: 2.5em; }
    .c-form_box__input.-radio .mwform-radio-field + .mwform-radio-field {
      margin-left: 0; }
  .c-form_box__input.-radio input[type="radio"] {
    display: none; }
    .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text {
      display: block;
      position: relative;
      min-width: 1.6rem;
      min-height: 1.6rem;
      padding-left: 2.4rem; }
      .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text:before, .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        content: ""; }
      .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text:before {
        border: solid 1px #aaaaaa; }
      .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text:after {
        visibility: hidden;
        transform: scale(0.35);
        background: #111111; }
    .c-form_box__input.-radio input[type="radio"]:checked + .mwform-radio-field-text:after {
      visibility: visible; }

.c-form_box__input.-check {
  padding: .5em 0;
  line-height: 1; }
  @media screen and (min-width: 576px) {
    .c-form_box__input.-check {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center; } }
  .c-form_box__input.-check .mwform-checkbox-field {
    display: block;
    margin-top: 1.6rem; }
    .c-form_box__input.-check .mwform-checkbox-field:not(:last-child) {
      margin-right: 4rem; }
    .c-form_box__input.-check .mwform-checkbox-field ~ .mwform-checkbox-field {
      margin-left: 0; }
  .c-form_box__input.-check input[type="checkbox"] {
    display: none; }
    .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text {
      display: block;
      position: relative;
      min-width: 1.6rem;
      min-height: 1.6rem;
      padding-left: 2.4rem; }
      .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text:before, .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        content: ""; }
      .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text:before {
        border: solid 2px #e0e0e0; }
      .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text:after {
        visibility: hidden;
        transform: rotate(-45deg) translate(25%, 75%);
        width: 1.6rem;
        height: 1rem;
        border-bottom: solid 3px #111111;
        border-left: solid 3px #111111;
        transform-origin: left bottom; }
    .c-form_box__input.-check input[type="checkbox"]:checked + .mwform-checkbox-field-text:after {
      visibility: visible; }

.c-form_box.-confirm .c-form_box__label {
  padding: 0; }

.c-form_box.-confirm .c-form_box__agreement {
  display: none; }

.c-form_box.-confirm .c-form_box__sheet tr.-top th,
.c-form_box.-confirm .c-form_box__sheet tr.-top td {
  vertical-align: top; }

.c-form_box.-confirm .c-form_box__sheet tr.-top .c-form_box__label {
  padding-top: .5em; }

@media screen and (max-width: 767px) {
  .c-form_box.-thanks .c-ttl_pl {
    font-size: 2rem; }
  .c-form_box.-thanks .c-btnwrap {
    margin-top: 3rem;
    text-align: center; } }

.c-form_box.-thanks .c-btnwrap {
  margin-top: 5rem; }

@media screen and (max-width: 991px) {
  .c-form__sheet:nth-child(n+2) {
    margin-top: 5rem; } }

@media screen and (max-width: 767px) {
  .c-form__sheet tbody tr {
    display: block;
    padding: 1.5em 0; }
    .c-form__sheet tbody tr th,
    .c-form__sheet tbody tr td {
      display: block;
      width: 100%;
      padding: 0; }
    .c-form__sheet tbody tr th {
      margin-bottom: .75em; }
  .c-form__ttl:before {
    margin-right: .25em;
    content: "■"; }
  .c-form__row {
    margin-top: -.75em; }
    .c-form__row > * {
      margin-top: .75em; } }

.c-form__sheet:nth-child(n+2) {
  margin-top: 7rem; }

.c-form__sheet thead th {
  padding: 1em;
  background: #111111;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1; }

.c-form__sheet tbody tr {
  border-bottom: solid 1px #cccccc; }

.c-form__sheet tbody th,
.c-form__sheet tbody td {
  padding: 1em;
  padding: 2em 1em;
  line-height: 1.6;
  text-align: left;
  vertical-align: middle; }

.c-form__sheet tbody th {
  width: 25%; }

.c-form__ttl.-required:after {
  margin-left: .5em;
  color: #111111;
  color: #cc1111;
  content: "【必須】"; }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .c-form__ttl.-required:after {
    display: block;
    margin-left: 0;
    font-size: .875em; } }

.c-form__input > *:not(:last-child) {
  margin-bottom: 1em; }

.c-form__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center; }
  .c-form__row > *:nth-last-child(n+2) {
    margin-right: .75em; }

.c-form__notes {
  position: relative;
  margin-top: .75em;
  padding-left: 1.25em;
  font-size: .875em; }
  .c-form__notes:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "※"; }

.c-form__error .error {
  color: #cc1111; }

.c-form__btn {
  margin-top: 5rem; }
  @media screen and (max-width: 767px) {
    .c-form__btn.-fx {
      display: block;
      text-align: center; }
    .c-form__btn > input.c-btn_arr {
      max-width: 70%; } }
  .c-form__btn > input.c-btn_arr {
    min-width: 1px;
    max-width: 32%;
    margin: 0 auto;
    padding: 1.7em 0;
    font-size: .875em; }
    @media screen and (max-width: 991px) {
      .c-form__btn > input.c-btn_arr {
        padding: 1.5em 0; } }
    .c-form__btn > input.c-btn_arr.-cancel {
      border-color: #aaaaaa;
      background: #aaaaaa;
      color: #ffffff; }
      @media screen and (min-width: 992px) {
        .c-form__btn > input.c-btn_arr.-cancel {
          margin-left: 3rem; }
          .c-form__btn > input.c-btn_arr.-cancel:hover {
            opacity: .5; } }
      @media screen and (max-width: 767px) {
        .c-form__btn > input.c-btn_arr.-cancel {
          margin-top: 1.5em; } }
    .c-form__btn > input.c-btn_arr:hover, .c-form__btn > input.c-btn_arr:focus {
      outline: none; }

@media screen and (max-width: 991px) {
  .c-form.-input .c-form__field.-short {
    width: 40%; }
  .c-form.-input .c-form__field.-middle {
    width: 60%; }
  .c-form.-input .c-form__field.-long {
    width: 80%; }
  .c-form.-input .c-form__field .text_field[size] {
    padding: .5em .75em; }
  .c-form.-input .c-form__field .text_field[size="0"], .c-form.-input .c-form__field .text_field:not([size]) {
    padding: .5em .75em; } }

@media screen and (max-width: 767px) {
  .c-form.-input .c-form__field.-short {
    width: 100%; }
  .c-form.-input .c-form__field.-middle {
    width: 100%; }
  .c-form.-input .c-form__field.-long {
    width: 100%; }
  .c-form.-input .c-form__field .text_field[size] {
    padding: .65em .75em; }
  .c-form.-input .c-form__field .text_field[size="0"], .c-form.-input .c-form__field .text_field:not([size]) {
    padding: .65em .75em; }
  .c-form.-input .c-form__field .select_field {
    padding: .5em .75em; } }

.c-form.-input .c-form__field.-short {
  width: 30%; }

.c-form.-input .c-form__field.-middle {
  width: 50%; }

.c-form.-input .c-form__field.-long {
  width: 70%; }

.c-form.-input .c-form__field.-full {
  width: 100%; }

.c-form.-input .c-form__field.-half {
  width: 48%; }
  + .c-form.-input .c-form__field.-half {
    margin-left: 4%; }

.c-form.-input .c-form__field .text_field {
  display: block; }
  .c-form.-input .c-form__field .text_field[size] {
    width: auto; }
  .c-form.-input .c-form__field .text_field[size="0"], .c-form.-input .c-form__field .text_field:not([size]) {
    width: 100%; }

.c-form.-input .c-form__field .select_field {
  padding: .45em .75em .4em; }

.c-form.-input .c-form__field textarea {
  display: block;
  width: 100%;
  height: 12em; }

@media screen and (max-width: 767px) {
  .c-form.-thanks .c-btnwrap {
    margin-top: 3rem;
    text-align: center; } }

.c-form.-thanks .c-btnwrap {
  margin-top: 5rem; }

/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden; }
  .c-gmap iframe,
  .c-gmap object,
  .c-gmap embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden; }
  .c-img_ofi .ofi {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none; }
    .c-img_ofi .ofi.cover {
      font-family: "object-fit: cover;object-position:center center;";
      object-fit: cover;
      object-position: center center; }
    .c-img_ofi .ofi.contain {
      margin: auto;
      font-family: "object-fit: contain;object-position:center center;";
      object-fit: contain; }

/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto; }

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-ta_left {
  text-align: left; }

.c-ta_center {
  text-align: center; }

.c-ta_right {
  text-align: right; }

@media screen and (min-width: 576px) {
  .c-ta_justify {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.c-pe_none {
  pointer-events: none; }

.c-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch; }

@media screen and (min-width: 992px) {
  .c-pe_sp {
    cursor: default;
    pointer-events: none; } }

.c-text_mb {
  margin-bottom: 2em; }

/*--------------------------------------------
  Navi
--------------------------------------------*/
.list_nav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 3em; }
  .list_nav li {
    list-style-type: none;
    width: 300px; }
    .list_nav li a {
      display: block;
      list-style: 1;
      padding: .7em;
      border: 1px solid #000000;
      background: #000000;
      color: #ffffff;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .list_nav li a:hover {
          background: #ffffff;
          color: #000000; } }

/*-------------------------------
  WP > EDITOR
-------------------------------*/
.wp-editor + .c-btnwrap {
  margin-top: 5rem; }

.wp-editor strong {
  font-weight: 700; }

.wp-editor em {
  font-style: italic; }

.wp-editor blockquote {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px; }

.wp-editor img.alignleft {
  margin: 0 auto 0 0; }

.wp-editor img.aligncenter {
  display: block;
  margin: 0 auto; }

.wp-editor img.alignright {
  display: block;
  margin: 0 0 0 auto; }

.wp-editor img[class*="wp-image-"],
.wp-editor img[class*="attachment-"] {
  height: auto;
  max-width: 100%; }

.wp-editor hr {
  margin: 2em 0; }

.wp-editor * + p {
  margin-top: 1em; }

.wp-editor * + ul,
.wp-editor * + ol,
.wp-editor * + .c-tbl_responsive {
  margin-top: 2em; }

.wp-editor * + h1,
.wp-editor * + h2,
.wp-editor * + h3,
.wp-editor * + h4,
.wp-editor * + h5,
.wp-editor * + h6 {
  margin-top: 1.5em; }

.wp-editor h2,
.wp-editor h3,
.wp-editor h4,
.wp-editor h5,
.wp-editor h6 {
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: .1em; }

.wp-editor h2 {
  position: relative;
  font-size: 1.75em;
  padding-top: .25em;
  padding-left: .5em; }
  .wp-editor h2:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 1.25em;
    height: 1.25em;
    background: #f3f3f3;
    z-index: -1; }

.wp-editor h3 {
  position: relative;
  font-size: 1.5em;
  padding-left: 2.25em; }
  .wp-editor h3:before, .wp-editor h3:after {
    position: absolute;
    content: "";
    left: 0;
    top: .6em;
    background: #111111; }
  .wp-editor h3:before {
    width: 1.75em;
    height: 1px; }
  .wp-editor h3:after {
    width: .35em;
    height: .35em;
    transform: translateY(-50%);
    border-radius: 50%; }

.wp-editor h4 {
  font-size: 1.25em; }

.wp-editor a {
  color: #111111;
  word-break: break-all; }
  .wp-editor a:focus {
    opacity: .7; }
  @media screen and (min-width: 992px) {
    .wp-editor a:hover {
      opacity: .7; } }

.wp-editor ul,
.wp-editor ol {
  padding-left: 2em;
  line-height: 1.5; }
  .wp-editor ul > li:not(:first-child),
  .wp-editor ol > li:not(:first-child) {
    margin-top: .6em; }

.wp-editor ul > li {
  list-style-type: disc; }

.wp-editor ol > li {
  list-style-type: decimal; }

.wp-editor table tr > th,
.wp-editor table tr > td {
  padding: 1em;
  border: 1px solid #ddd; }

.wp-editor table tr > th {
  background: #eee; }

@media screen and (max-width: 991px) {
  .wp-editor * + p,
  .wp-editor * + ul,
  .wp-editor * + ol,
  .wp-editor * + .c-tbl_responsive {
    margin-top: 1.2em; }
  .wp-editor * + h1,
  .wp-editor * + h2,
  .wp-editor * + h3,
  .wp-editor * + h4,
  .wp-editor * + h5,
  .wp-editor * + h6 {
    margin-top: 2.5em; }
  .wp-editor h3 {
    border-bottom: solid 1px #111111;
    padding-left: .4em;
    padding-bottom: .25em; }
    .wp-editor h3:before {
      display: none; }
    .wp-editor h3:after {
      top: initial;
      bottom: 0;
      transform: translateY(50%); }
  .wp-editor ul > li:not(:first-child),
  .wp-editor ol > li:not(:first-child) {
    margin-top: .4em; } }

/*-------------------------------
  WP > PAGINATION
-------------------------------*/
.wp-pagenavi {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1; }
  .wp-pagenavi > * {
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 .5em; }
  .wp-pagenavi .current {
    font-weight: 700; }
  .wp-pagenavi a.previouspostslink {
    position: relative;
    padding-left: 2em;
    margin-right: 3em; }
    .wp-pagenavi a.previouspostslink:before, .wp-pagenavi a.previouspostslink:after {
      content: "";
      left: 0; }
    .wp-pagenavi a.previouspostslink:before {
      transform-origin: left bottom;
      transform: rotate(-45deg); }
  .wp-pagenavi a.nextpostslink {
    position: relative;
    padding-right: 2em;
    margin-left: 3em; }
    .wp-pagenavi a.nextpostslink:before, .wp-pagenavi a.nextpostslink:after {
      content: "";
      right: 0; }
    .wp-pagenavi a.nextpostslink:before {
      transform-origin: right bottom;
      transform: rotate(45deg); }
  .wp-pagenavi a:before, .wp-pagenavi a:after {
    position: absolute;
    background: #111111;
    top: .5em; }
  .wp-pagenavi a:before {
    width: .45em;
    height: .5px; }
  .wp-pagenavi a:after {
    width: 1.25em;
    height: 1px; }
  @media screen and (min-width: 992px) {
    .wp-pagenavi a:hover {
      font-weight: 700; } }
  @media screen and (max-width: 575px) {
    .wp-pagenavi a.previouspostslink {
      margin-right: 1em; }
    .wp-pagenavi a.nextpostslink {
      margin-left: 1em; }
    .wp-pagenavi .extend {
      display: none; } }

/*--------------------------------------------
  BREADCRUMBS
--------------------------------------------*/
@media screen and (max-width: 991px) {
  .l-content {
    padding-top: 9rem; } }

.l-main.-under {
  padding: 10rem 0 15rem; }
  @media screen and (max-width: 767px) {
    .l-main.-under {
      padding: 6rem 0; } }

.l-breadcrumb {
  line-height: 1;
  margin-top: 1.875em; }
  .l-breadcrumb__list {
    justify-content: flex-start;
    align-items: center; }
    .l-breadcrumb__list li {
      position: relative;
      padding-right: 1.8125em;
      margin-right: .5625em; }
      .l-breadcrumb__list li:after {
        position: absolute;
        content: "";
        right: 0;
        top: 50%;
        width: 1.25em;
        height: 1px;
        background: #111111; }
      .l-breadcrumb__list li a {
        position: relative;
        display: block;
        padding: 5px 0;
        max-width: 30em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
        .l-breadcrumb__list li a:after {
          position: absolute;
          content: "";
          right: 0;
          left: initial;
          bottom: 0;
          width: 0%;
          height: 1px;
          background: #111111;
          transition: all 0.6s; }
        .l-breadcrumb__list li a:hover:after {
          left: 0;
          right: initial;
          width: 100%; }
      .l-breadcrumb__list li:last-child {
        padding-right: 0;
        margin-right: 0; }
        .l-breadcrumb__list li:last-child:after {
          display: none; }
        .l-breadcrumb__list li:last-child a {
          pointer-events: none; }
          .l-breadcrumb__list li:last-child a:after {
            display: none; }

/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999; }
  .l-header .c-inner {
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: center; }
  .l-header__toggle {
    position: relative;
    z-index: 102;
    width: 5rem;
    height: 5rem; }
    .l-header__toggle .so_toggle {
      position: absolute;
      background: transparent;
      width: 72%;
      height: 72%;
      padding: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      .l-header__toggle .so_toggle .hamburger-icon {
        background: transparent;
        top: 0; }
        .l-header__toggle .so_toggle .hamburger-icon:before, .l-header__toggle .so_toggle .hamburger-icon:after {
          background: #111111;
          top: 0; }
        .l-header__toggle .so_toggle .hamburger-icon:before {
          transform: translateY(-4px); }
        .l-header__toggle .so_toggle .hamburger-icon:after {
          transform: translateY(4px); }
  .slideout-open .l-header__toggle {
    background: rgba(255, 255, 255, 0); }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:before, .slideout-open .l-header__toggle .so_toggle .hamburger-icon:after {
      background: #fff; }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:before {
      transform: rotate(45deg); }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:after {
      transform: rotate(-45deg); }
  @media screen and (min-width: 992px) {
    .l-header__left {
      background: #fff;
      padding: 3rem 5rem 3rem 3rem;
      align-items: center; }
    .l-header__logo {
      margin-right: 5rem; }
      .l-header__logo a img {
        height: 9.9rem; }
      .l-header__logo a:hover {
        opacity: .5; } }

@media screen and (min-width: 992px) and (min-width: 1300px) {
  .l-header__menu_sub {
    padding-right: 3rem; } }
  @media screen and (min-width: 992px) {
    .l-header__menu_sub li {
      font-size: .875em; }
      .l-header__menu_sub li:nth-child(n+2) {
        margin-left: .675em; }
      .l-header__menu_sub li a {
        background: #111111;
        color: #fff;
        padding: .5em .715em .45em;
        border: solid 1px #111111; }
        .l-header__menu_sub li a:hover {
          background: #fff;
          color: #111111; } }

@media screen and (min-width: 992px) and (max-width: 1599px) {
  .l-header__left {
    padding: 3rem; }
  .l-header__logo {
    margin-right: 3rem; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .l-header__left {
    padding: 2rem; }
  .l-header__logo {
    margin-right: 2rem; }
    .l-header__logo a img {
      height: 7.7rem; } }
  @media screen and (max-width: 1299px) {
    .l-header__left {
      padding: 2rem 1em; }
    .l-header__menu_sub li a {
      padding: 0.5em 0.5em 0.45em; }
    .l-header__menu_sub li:nth-child(n+2) {
      margin-left: 0.2em; } }
  @media screen and (max-width: 991px) {
    .l-header {
      background: #fff;
      padding: 2rem 1rem; }
      .l-header__logo a img {
        height: 5rem; } }
  @media screen and (max-width: 767px) {
    .l-header__logo a img {
      height: 5rem; } }

/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
@media screen and (min-width: 992px) {
  .l-gnavi {
    position: static;
    background: transparent;
    width: auto;
    overflow: hidden; }
    .l-gnavi__menu {
      line-height: 1; }
      .l-gnavi__menu li a {
        position: relative;
        display: block;
        padding: .3em .675em .25em; }
        .l-gnavi__menu li a:hover {
          background: #111111;
          color: #fff; } }
  @media screen and (min-width: 992px) and (max-width: 1599px) {
    .l-gnavi__menu li {
      font-size: .875em; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .l-gnavi__menu li {
      font-size: 1em; } }

@media screen and (max-width: 991px) {
  .l-gnavi {
    background: #111111;
    padding: 11rem 3rem 3rem;
    right: 0;
    transform: translateX(100%);
    width: 35%; }
    .l-gnavi__menu {
      display: block; }
      .l-gnavi__menu li:nth-child(n+2) {
        margin-top: 1em; }
      .l-gnavi__menu li a {
        color: #fff;
        padding: 0; }
      .l-gnavi__menu.-bottom {
        margin-top: 2em; }
        .l-gnavi__menu.-bottom li {
          font-size: .875em; }
          .l-gnavi__menu.-bottom li a {
            display: block;
            text-align: center;
            background: #fff;
            color: #111111;
            padding: .5em; }
    .slideout-open .l-gnavi {
      transform: translateX(0); } }

@media screen and (max-width: 767px) {
  .l-gnavi {
    width: 40%; } }

@media screen and (max-width: 575px) {
  .l-gnavi {
    padding: 11rem 3rem;
    width: 100%;
    font-size: 1.8rem; } }

/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer {
  position: relative;
  line-height: 1; }
  @media screen and (min-width: 992px) {
    .l-footer:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: calc(100% - 12rem);
      height: 100%;
      background: #f5f5f5; }
    .-under .l-footer:before {
      display: none; } }
  .l-footer .c-inner {
    position: relative;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2; }
  .l-footer__info {
    background: #111111;
    color: #fff;
    padding: 5rem; }
  .l-footer__title {
    font-size: 1.5em;
    line-height: 1; }
  .l-footer__copy {
    font-size: .875em;
    line-height: 1;
    margin-top: 1.5em; }
  .l-footer__totop a {
    width: 12rem;
    padding: 2rem 0;
    display: block;
    background: #111111;
    color: #fff;
    text-align: center;
    font-size: .75em; }
    @media screen and (min-width: 992px) {
      .l-footer__totop a:hover {
        opacity: .5; } }
    .l-footer__totop a span {
      position: relative;
      padding: 1em 0;
      display: block; }
      .l-footer__totop a span:before, .l-footer__totop a span:after {
        position: absolute;
        content: "";
        top: 0;
        width: .5em;
        height: 1px;
        background: #fff;
        transition: all 0.6s; }
      .l-footer__totop a span:before {
        right: 50%;
        transform-origin: right top;
        transform: rotate(-30deg); }
      .l-footer__totop a span:after {
        left: 50%;
        transform-origin: left top;
        transform: rotate(30deg); }
  @media screen and (max-width: 991px) {
    .l-footer {
      background: #111111; }
      .l-footer .c-inner {
        display: block; }
      .l-footer__info {
        text-align: center;
        padding: 3rem 0 2rem; }
      .l-footer__title {
        font-size: 1.25em; }
      .l-footer__copy {
        font-size: .75em; }
      .l-footer__totop a {
        width: 100%;
        padding: 0 0 3rem; }
        .l-footer__totop a span {
          padding-bottom: 0; } }

/*--------------------------------------------
  PAGE
--------------------------------------------*/
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key {
  position: relative;
  width: 100%;
  height: 100vh; }
  @media screen {
    .p-idx_key__inner:not(.slick-initialized) {
      display: none; } }
  .p-idx_key__img {
    position: relative;
    width: calc(100% - 12rem);
    height: calc(100% - 8rem);
    margin-left: auto;
    padding-top: 0;
    overflow: hidden; }
    .p-idx_key__img video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      overflow: hidden;
      transform: translate(-50%, -50%); }
  .p-idx_key__text {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #111111;
    color: #fff;
    padding: 6rem 7rem; }
    .p-idx_key__text .title {
      font-size: 2.75em;
      line-height: 1.275; }
    .p-idx_key__text .sup {
      position: relative;
      font-size: .75em;
      line-height: 1;
      padding-left: 5em;
      margin-top: 2.3em; }
      .p-idx_key__text .sup:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 50%;
        width: 4em;
        height: 1px;
        background: #fff; }
  .p-idx_key__side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: #fff;
    width: 12rem;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column; }
    .p-idx_key__side .sup {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      font-size: .75em;
      margin-bottom: 3.666em; }
    .p-idx_key__side .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 8rem;
      background: #111111;
      font-size: .75em;
      line-height: 1;
      color: #fff; }
      .p-idx_key__side .btn span {
        display: inline-block;
        position: relative;
        padding: 1em 0; }
        .p-idx_key__side .btn span:before, .p-idx_key__side .btn span:after {
          position: absolute;
          content: "";
          bottom: 0;
          width: .5em;
          height: 1px;
          background: #fff;
          transition: all 0.6s; }
        .p-idx_key__side .btn span:before {
          right: 50%;
          transform-origin: right bottom;
          transform: rotate(30deg); }
        .p-idx_key__side .btn span:after {
          left: 50%;
          transform-origin: left bottom;
          transform: rotate(-30deg); }
      @media screen and (min-width: 992px) {
        .p-idx_key__side .btn:hover {
          opacity: .75; }
          .p-idx_key__side .btn:hover span:before, .p-idx_key__side .btn:hover span:after {
            bottom: -.5em; } }
  @media screen and (max-width: 1199px) {
    .p-idx_key__text .title {
      font-size: 2.25em; } }
  @media screen and (max-width: 991px) {
    .p-idx_key {
      height: 75vw; }
      .p-idx_key__img {
        width: 100%; }
      .p-idx_key__text {
        padding: 4rem; }
        .p-idx_key__text .title {
          font-size: 1.75em; }
      .p-idx_key__side {
        display: none; } }
  @media screen and (max-width: 767px) {
    .p-idx_key {
      height: auto; }
      .p-idx_key__img {
        height: 50vw; }
      .p-idx_key__text {
        position: static;
        padding: 2rem 0;
        text-align: center; }
        .p-idx_key__text .sup {
          padding-left: 0;
          margin-top: 1em; }
          .p-idx_key__text .sup:before {
            display: none; } }
  @media screen and (max-width: 575px) {
    .p-idx_key__text .title {
      font-size: 1.5em; } }

/*-------------------------------
  INDEX > ABOUT
-------------------------------*/
.p-idx_about {
  padding-top: 15rem;
  overflow: hidden; }
  .p-idx_about .c-inner {
    position: relative;
    padding-top: 15rem; }
    @media screen and (min-width: 992px) {
      .p-idx_about .c-inner {
        width: 1600px;
        max-width: 97%;
        margin-left: 0; } }
  .p-idx_about .c-ttl_v {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    height: 30rem; }
  .p-idx_about__img {
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    z-index: 2; }
    .p-idx_about__img img {
      max-width: 100%;
      height: auto; }
  .p-idx_about__text {
    position: relative;
    width: 50%;
    margin-left: auto;
    padding: 10rem 20rem 15rem 10rem; }
    .p-idx_about__text > * {
      position: relative;
      z-index: 2; }
    .p-idx_about__text:before {
      position: absolute;
      content: "";
      width: 100vw;
      min-width: 100%;
      left: -20rem;
      top: 0;
      height: 100%;
      background: #f7f7f7;
      z-index: 1; }
    .p-idx_about__text .c-btnwrap {
      margin-top: 5rem; }

@media screen and (max-width: 1599px) and (min-width: 1200px) {
  .p-idx_about .c-inner {
    padding-top: 10rem; }
  .p-idx_about .c-ttl_v {
    height: 20rem; }
  .p-idx_about__text {
    padding: 10rem 10rem 10rem 5rem; }
    .p-idx_about__text:before {
      left: -10rem; } }
  @media screen and (max-width: 1199px) {
    .p-idx_about .c-inner {
      padding-top: 10rem; }
    .p-idx_about .c-ttl_v {
      height: 20rem; }
    .p-idx_about__text {
      padding: 10rem 10rem 10rem 5rem; }
      .p-idx_about__text:before {
        left: -10rem; } }
  @media screen and (max-width: 991px) {
    .p-idx_about {
      padding: 10rem 0; }
      .p-idx_about .c-inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 0; }
      .p-idx_about__img {
        position: static;
        width: 40%; }
      .p-idx_about__text {
        position: static;
        width: 55%;
        padding: 0; }
        .p-idx_about__text:before {
          display: none; }
      .p-idx_about .c-btnwrap {
        width: 100%;
        margin-top: 3rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_about .c-inner {
      display: block; }
    .p-idx_about__img {
      width: 60%;
      margin: 0 auto; }
    .p-idx_about__text {
      padding-top: 3rem;
      width: 100%; }
      .p-idx_about__text .c-ttl_pl {
        text-align: center; } }
  @media screen and (max-width: 767px) {
    .p-idx_about {
      padding: 6rem 0; } }
  @media screen and (max-width: 575px) {
    .p-idx_about__img {
      width: 100%; } }

/*-------------------------------
  INDEX > ORGANIZATION
-------------------------------*/
.p-idx_organization {
  padding: 15rem 0;
  overflow: hidden; }
  .p-idx_organization .c-org_list {
    position: relative; }
    .p-idx_organization .c-org_list li {
      position: relative;
      z-index: 2; }
    @media screen and (min-width: 992px) {
      .p-idx_organization .c-org_list:before {
        position: absolute;
        content: "";
        right: 20rem;
        top: 50%;
        width: 100vw;
        height: 100vh;
        background: #f7f7f7; } }
  @media screen and (max-width: 991px) {
    .p-idx_organization {
      padding: 10rem 0;
      background: #f7f7f7; } }
  @media screen and (max-width: 767px) {
    .p-idx_organization {
      padding: 6rem 0; } }

/*-------------------------------
  INDEX > INFORMATION
-------------------------------*/
.p-idx_information {
  padding: 15rem 0; }
  .p-idx_information .c-inner {
    justify-content: space-between;
    align-items: stretch; }
  .p-idx_information__sec {
    width: 48%; }
  .p-idx_information__title {
    line-height: 1;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4rem; }
    .p-idx_information__title .c-ttl_sup {
      margin-bottom: 0; }
  @media screen and (max-width: 991px) {
    .p-idx_information {
      padding: 10rem 0; }
      .p-idx_information .c-inner {
        display: block; }
      .p-idx_information__sec {
        width: 100%; }
        .p-idx_information__sec.-event {
          margin-top: 10rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_information {
      padding: 6rem 0; }
      .p-idx_information__sec.-event {
        margin-top: 6rem; } }

/*-------------------------------
  INDEX > COLUMN
-------------------------------*/
.p-idx_column {
  padding-bottom: 20rem;
  overflow: hidden; }
  .p-idx_column__head {
    margin-bottom: 4rem; }
    .p-idx_column__head .c-inner {
      justify-content: space-between;
      align-items: flex-end; }
    .p-idx_column__head .c-ttl_sup {
      margin-bottom: 0; }
  .p-idx_column__body {
    position: relative; }
    .p-idx_column__body .c-inner {
      position: relative;
      z-index: 2; }
    @media screen and (min-width: 992px) {
      .p-idx_column__body:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        width: calc(100% - 12rem);
        height: 100vh;
        background: #f5f5f5;
        z-index: 1; } }
  @media screen and (max-width: 991px) {
    .p-idx_column {
      padding: 10rem 0;
      background: #f5f5f5; } }
  @media screen and (max-width: 767px) {
    .p-idx_column {
      padding: 6rem 0; } }

/*============================================

  OTHER PAGE

============================================*/
/*-------------------------------
  BASE > ABOUT
-------------------------------*/
.p-base_about {
  margin-bottom: 10rem; }
  @media screen and (max-width: 991px) {
    .p-base_about {
      margin-bottom: 6rem; } }

.p-base_about_top {
  justify-content: space-between;
  margin-bottom: 5rem; }
  @media screen and (max-width: 991px) {
    .p-base_about_top {
      display: block;
      margin-bottom: 3rem; } }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    .p-base_about_top__img {
      float: left; }
    .p-base_about_top__text {
      width: 100%; }
      .p-base_about_top__text > p:nth-child(2) {
        float: right;
        width: 57%;
        margin-bottom: 0; }
      .p-base_about_top__text > p:nth-child(3) {
        clear: both;
        padding-top: 1em; } }
  @media screen and (max-width: 767px) {
    .p-base_about_top__img {
      width: 100%;
      margin-bottom: 3rem; }
    .p-base_about_top__text {
      width: 100%; }
      .p-base_about_top__text .sub_box .image img {
        width: 11rem; }
      .p-base_about_top__text .sub_box .text {
        max-width: calc(95% - 11rem); }
        .p-base_about_top__text .sub_box .text h3 {
          font-size: 1.3em; } }
  @media screen and (max-width: 575px) {
    .p-base_about_top__text .sub_box {
      display: block;
      padding: 2rem; }
      .p-base_about_top__text .sub_box .image {
        margin-bottom: 1em;
        text-align: center; }
        .p-base_about_top__text .sub_box .image img {
          width: auto;
          height: 8rem; }
      .p-base_about_top__text .sub_box .text {
        max-width: 100%; }
        .p-base_about_top__text .sub_box .text h3 {
          text-align: center; } }
  .p-base_about_top__img {
    width: 40%; }
    .p-base_about_top__img img {
      max-width: 100%;
      height: auto; }
  .p-base_about_top__text {
    width: 55%; }
    .p-base_about_top__text > p {
      margin-bottom: 1em; }
    .p-base_about_top__text .sub_box {
      justify-content: space-between;
      margin-top: 3rem;
      padding: 3rem;
      background: #f5f5f5; }
      .p-base_about_top__text .sub_box .image img {
        max-width: 100%;
        height: auto; }
      .p-base_about_top__text .sub_box .text {
        max-width: calc(95% - 117px);
        font-size: .875em; }
        .p-base_about_top__text .sub_box .text h3 {
          margin-bottom: .5em;
          font-size: 1.5em; }

@media screen and (max-width: 991px) {
  .p-base_about_bottom__text {
    padding: 3rem; } }

@media screen and (max-width: 575px) {
  .p-base_about_bottom__text {
    padding: 3rem; }
    .p-base_about_bottom__text h3 {
      margin-bottom: 1em;
      font-size: 1.3em;
      text-align: center; } }

.p-base_about_bottom > p {
  margin-bottom: 3rem; }

.p-base_about_bottom__text {
  padding: 5rem;
  background: #f5f5f5; }
  .p-base_about_bottom__text h3 {
    margin-bottom: .75em;
    font-size: 1.5em; }
  .p-base_about_bottom__text p {
    line-height: 1.6; }
    .p-base_about_bottom__text p a {
      text-decoration: underline;
      word-break: break-all; }
      @media screen and (min-width: 992px) {
        .p-base_about_bottom__text p a:hover {
          text-decoration: none; } }

/*-------------------------------
  BASE > ORGANIZATION
-------------------------------*/
.p-base_organization__img img {
  max-width: 100%; }

/*-------------------------------
  CURRICULUM
-------------------------------*/
.p-curriculum_main .c-btnwrap {
  width: 100%;
  max-width: 920px;
  margin-top: 3rem; }
  @media screen and (max-width: 991px) {
    .p-curriculum_main .c-btnwrap {
      width: 92%;
      max-width: 100%; }
      .p-curriculum_main .c-btnwrap a {
        width: 30%;
        min-width: 32.5%;
        padding: 1.7em 1em; } }
  @media screen and (max-width: 767px) {
    .p-curriculum_main .c-btnwrap {
      display: block; }
      .p-curriculum_main .c-btnwrap a {
        width: 100%; }
        .p-curriculum_main .c-btnwrap a:nth-child(n+2) {
          margin-top: 1em; } }
  .p-curriculum_main .c-btnwrap span {
    display: block;
    margin-bottom: .3em;
    font-size: 1.4rem; }

.p-curriculum_form {
  margin-top: 5rem; }

/*-------------------------------
  WORK SHOP
-------------------------------*/
@media screen and (max-width: 767px) {
  .p-workshop_main .c-tbl table tr:first-child th:first-child {
    width: 35%; }
  .p-workshop_main .c-tbl table th {
    width: 65%; } }

.p-workshop_main .c-page_lead {
  margin-bottom: 5rem; }

.p-workshop_main .c-tbl table tr:first-child th:first-child {
  width: 30%; }

.p-workshop_main .c-tbl table th {
  width: 70%; }

.p-workshop_main .c-tbl table td span {
  color: #cc1111; }

/*-------------------------------
  LECTURE
-------------------------------*/
.p-lecture_main .c-page_lead {
  margin-bottom: 10rem;
  gap: 30px; }
  @media screen and (min-width: 991px) {
    .p-lecture_main .c-page_lead {
      padding-right: 80px;
      padding-left: 80px;
      gap: 80px; } }
  .p-lecture_main .c-page_lead figure {
    margin-bottom: 30px; }
  .p-lecture_main .c-page_lead img {
    transition: .6s; }
  .p-lecture_main .c-page_lead a {
    overflow: hidden;
    text-decoration: none; }
    .p-lecture_main .c-page_lead a:hover img {
      scale: 1.1; }
    .p-lecture_main .c-page_lead a:hover figcaption {
      background-color: #ffffff;
      color: #000000; }
  .p-lecture_main .c-page_lead .c-page_lead__img {
    width: 100%;
    overflow: hidden; }
  .p-lecture_main .c-page_lead figcaption {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-right: 1em;
    padding-bottom: 10px;
    padding-left: 1em;
    border: 1px solid #000000;
    border-top: none;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    transition: .6s;
    text-wrap: balance; }
  .p-lecture_main .c-page_lead p a {
    text-decoration: underline; }
    @media screen and (min-width: 992px) {
      .p-lecture_main .c-page_lead p a:hover {
        text-decoration: none; } }

/*-------------------------------
  量子情報理論への招待
-------------------------------*/
.p-invitation_wrap {
  margin-bottom: 5em; }
  .p-invitation_wrap:last-child {
    margin-bottom: 0; }

.p-invitation_wrap__box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  padding-bottom: .5em;
  border-bottom: 1px solid #dddddd; }
  @media screen and (max-width: 767px) {
    .p-invitation_wrap__box {
      align-items: stretch; } }
  .p-invitation_wrap__box p {
    position: relative;
    width: 62%;
    padding-left: 1em; }
    @media screen and (max-width: 767px) {
      .p-invitation_wrap__box p {
        width: 100%; } }
    .p-invitation_wrap__box p:before {
      display: block;
      position: absolute;
      top: .6em;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #000000;
      content: ""; }

.p-invitation_wrap__test {
  width: 18%; }
  @media screen and (max-width: 767px) {
    .p-invitation_wrap__test {
      width: 48%; } }
  .p-invitation_wrap__test a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1em;
    border: 1px solid #000000;
    background: #000000;
    color: #ffffff;
    font-size: .9em;
    line-height: 1;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .p-invitation_wrap__test a:hover {
        background: #ffffff;
        color: #000000; } }
    @media screen and (max-width: 767px) {
      .p-invitation_wrap__test a {
        line-height: 1.3; } }

/*-------------------------------
  公開のオンライン講座
-------------------------------*/
/*-------------------------------
  量子通信の基礎
-------------------------------*/
@media screen and (max-width: 767px) {
  .p-basic li {
    padding: 1em;
    text-align: center; }
    .p-basic li p {
      margin-bottom: .5em;
      line-height: 1.3; } }

@media screen and (min-width: 992px) {
  .p-basic_list {
    width: 80%; } }

.p-basic_list li {
  padding: .5em;
  border-bottom: 1px solid #dddddd;
  line-height: 1; }
  @media screen and (min-width: 768px) {
    .p-basic_list li {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  .p-basic_list li p {
    font-weight: 600;
    font-size: 1.1em; }
  .p-basic_list li a {
    display: inline-block;
    padding: .5em;
    border: 1px solid #000000;
    background: #000000;
    color: #ffffff;
    font-size: .9em; }
    @media screen and (min-width: 992px) {
      .p-basic_list li a:hover {
        background: #ffffff;
        color: #000000; } }

/*-------------------------------
  PRIVACY > MAIN
-------------------------------*/
.p-privacy_policy__text {
  margin-bottom: 5rem; }

.p-privacy_policy__sec:not(:last-child) {
  margin-bottom: 5rem; }

.p-privacy_policy__sec .c-ttl_pl {
  margin-bottom: 0;
  line-height: 1.4; }

.p-privacy_policy__sec p {
  margin-top: 1em; }
  .p-privacy_policy__sec p + .c-list {
    margin-top: .5em; }

/*-------------------------------
  NEWS > SINGLE
-------------------------------*/
.p-news_single__head {
  margin-bottom: 1.25em;
  padding-bottom: 1em;
  border-bottom: solid 1px #111111;
  font-size: 3rem; }
  .p-news_single__head time {
    display: block;
    margin-bottom: 1em;
    font-size: .5em;
    line-height: 1; }
  .p-news_single__head .title {
    line-height: 1.4; }

/*-------------------------------
  EVENT > SINGLE
-------------------------------*/
.p-event_single__head {
  margin-bottom: 1.25em;
  padding-bottom: 1em;
  border-bottom: solid 1px #111111;
  font-size: 3rem; }
  .p-event_single__head time {
    display: block;
    margin-bottom: 1em;
    font-size: .5em;
    line-height: 1; }
  .p-event_single__head .title {
    line-height: 1.4; }

/*-------------------------------
  COLUMN > SINGLE
-------------------------------*/
.p-column_single__head {
  margin-bottom: 1.25em;
  padding-bottom: 1em;
  border-bottom: solid 1px #111111;
  font-size: 3rem; }
  .p-column_single__head .info {
    align-items: center;
    margin-bottom: 1em;
    font-size: .5em;
    line-height: 1; }
    .p-column_single__head .info .term {
      margin-left: 1em;
      padding: .35em .75em;
      background: #111111;
      color: #ffffff; }
  .p-column_single__head .title {
    line-height: 1.4; }

/*-------------------------------
  INTERN
-------------------------------*/
.p-intern_guideline {
  margin-top: 10rem; }
  @media screen and (max-width: 991px) {
    .p-intern_guideline {
      margin-top: 6rem; } }
  @media screen and (min-width: 768px) {
    .p-intern_guideline__sheet tr th {
      width: 25%; }
    .p-intern_guideline__sheet tr th,
    .p-intern_guideline__sheet tr td {
      padding: 1.5em; }
    .p-intern_guideline__sheet tr:not(:first-child) th {
      border-top-color: #ffffff; }
    .p-intern_guideline__sheet tr:not(:last-child) th {
      border-bottom-color: #ffffff; } }
  @media screen and (max-width: 767px) {
    .p-intern_guideline .c-tbl tr {
      display: block; }
      .p-intern_guideline .c-tbl tr th,
      .p-intern_guideline .c-tbl tr td {
        display: block;
        width: 100%;
        padding: 1.5em; }
      .p-intern_guideline .c-tbl tr th {
        padding: 1em 1.5em; }
      .p-intern_guideline .c-tbl tr td {
        padding: 1.75em 1.5em; } }
  .p-intern_guideline__sheet {
    margin-bottom: 5rem; }

.p-intern_archive {
  margin-top: 10rem; }
  @media screen and (max-width: 991px) {
    .p-intern_archive {
      margin-bottom: 6rem; } }
  @media screen and (max-width: 767px) {
    .p-intern_archive .c-tbl thead {
      display: none; }
    .p-intern_archive .c-tbl tr {
      display: block;
      margin-top: -1px;
      border: solid 1px #111111; }
    .p-intern_archive .c-tbl td {
      display: block;
      border: none; }
      .p-intern_archive .c-tbl td:first-child {
        width: 100%;
        margin-bottom: 1em;
        padding: .5em 1em;
        background: #111111;
        color: #ffffff;
        font-weight: 700;
        font-size: 1.125em;
        text-align: left; }
      .p-intern_archive .c-tbl td:nth-child(n+2) {
        position: relative;
        padding: 0 1.125em 0 2.125em;
        padding-left: 7em; }
        .p-intern_archive .c-tbl td:nth-child(n+2):before {
          position: absolute;
          top: 0;
          left: 1em;
          font-weight: 700;
          content: attr(data-ttl) " / "; }
      .p-intern_archive .c-tbl td:nth-child(n+3) {
        margin-top: .5em; }
      .p-intern_archive .c-tbl td:last-child {
        padding-bottom: 1.125em; } }

/*-------------------------------
  APPLICATION
-------------------------------*/
.p-app_form [data-input="age"] {
  width: 5em; }

/*-------------------------------
  NOT FOUND
-------------------------------*/
@media screen and (min-width: 992px) {
  .p-notfound_main .c-ttl_sup {
    margin-bottom: 1.25em; } }

.p-notfound_main .c-btnwrap {
  margin-top: 5rem;
  text-align: center; }

/*--------------------------------------------
  print
--------------------------------------------*/
