@charset "utf-8";
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key{
  position: relative;
  width: 100%;
  height: 100vh;
  @include mq(screen){
    &__inner{
      &:not(.slick-initialized){
        display: none;
      }
    }
  }
  &__img{
    position: relative;
    width: calc(100% - 12rem);
    height: calc(100% - 8rem);
    margin-left: auto;
    padding-top: 0;
    overflow: hidden;
    video{
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      overflow: hidden;
      transform:translate(-50%,-50%);
    }
  }
  &__text{
    position: absolute;
    right: 0;
    bottom: 0;
    background: $font_color;
    color:#fff;
    padding: $margin_sm*2 $margin_lg;
    .title{
      font-size: 2.75em;
      line-height: 1.275;
    }
    .sup{
      position: relative;
      font-size: .75em;
      line-height: 1;
      padding-left: 5em;
      margin-top: 2.3em;
      &:before{
        position: absolute;
        content:"";
        left: 0;
        bottom: 50%;
        width: 4em;
        height: 1px;
        background: #fff;
      }
    }
  }
  &__side{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: #fff;
    width: 12rem;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    .sup{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      font-size: .75em;
      margin-bottom: 3.666em;
    }
    .btn{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 8rem;
      background: $font_color;
      font-size: .75em;
      line-height: 1;
      color: #fff;
      span{
        display: inline-block;
        position: relative;
        padding: 1em 0;
        &:before,
        &:after{
          position: absolute;
          content:"";
          bottom:0 ;
          width: .5em;
          height: 1px;
          background: #fff;
          transition:$transition_val;
        }
        &:before{
          right:50%;
          transform-origin: right bottom;
          transform:rotate(30deg);
        }
        &:after{
          left:50%;
          transform-origin: left bottom;
          transform:rotate(-30deg);
        }
      }
      @include mq(sm-){
        &:hover{
          opacity: .75;
          span{
            &:before,
            &:after{
              bottom:-.5em;
            }
          }
        }
      }
    }
  }
  @include mq(md){
    &__text{
      .title{
        font-size: 2.25em;
      }
    }
  }
  @include mq(sm){
    height: 75vw;
    &__img{
      width: 100%;
    }
    &__text{
      padding: $margin_ss*2;
      .title{
        font-size: 1.75em;
      }
    }
    &__side{
      display: none;
    }
  }
  @include mq(xs){
    height: auto;
    &__img{
      height: 50vw;
    }
    &__text{
      position: static;
      padding: $margin_ss 0;
      text-align: center;
      .sup{
        padding-left: 0;
        margin-top: 1em;
        &:before{
          display: none;
        }
      }
    }
  }
  @include mq(ss){
    &__text{
      .title{
        font-size: 1.5em;
      }
    }
  }
}

/*-------------------------------
  INDEX > ABOUT
-------------------------------*/
.p-idx_about{
  padding-top: $margin_md*3;
  overflow: hidden;
  .c-inner{
    position: relative;
    padding-top: $margin_md*3;
    @include mq(sm-){
      width: 1600px;
      max-width: 97%;
      margin-left: 0;
    }
  }
  .c-ttl_v{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    height: $margin_md*6;
  }
  &__img{
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    z-index: 2;
    img{
      max-width: 100%;
      height: auto;
    }
  }
  &__text{
    position: relative;
    width: 50%;
    margin-left: auto;
    padding: $margin_md*2 $margin_md*4 $margin_md*3 $margin_md*2;
    >*{
      position: relative;
      z-index: 2
    }
    &:before{
      position: absolute;
      content:"";
      width: 100vw;
      min-width: 100%;
      left: -$margin_md*4;
      top: 0;
      height: 100%;
      background: #f7f7f7;
      z-index: 1;
    }
    .c-btnwrap{
      margin-top: $margin_md;
    }
  }
  @include mq(w1600){
    @include mq(md-){
      .c-inner{
        padding-top: $margin_md*2;
      }
      .c-ttl_v{
        height: $margin_md*4;
      }
      &__text{
        padding: $margin_md*2 $margin_md*2 $margin_md*2 $margin_md;
        &:before{
          left: -$margin_md*2;
        }
      }
    }
  }
  @include mq(md){
    .c-inner{
      padding-top: $margin_md*2;
    }
    .c-ttl_v{
      height: $margin_md*4;
    }
    &__text{
      padding: $margin_md*2 $margin_md*2 $margin_md*2 $margin_md;
      &:before{
        left: -$margin_md * 2;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md*2 0;
    .c-inner{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 0;
    }
    &__img{
      position: static;
      width: 40%;
    }
    &__text{
      position: static;
      width: 55%;
      padding: 0;
      &:before{
        display: none;
      }
    }
    .c-btnwrap{
      width: 100%;
      margin-top: $margin_sm;
    }
  }
  @include mq(xs){
    .c-inner{
      display: block;
    }
    &__img{
      width: 60%;
      margin: 0 auto;
    }
    &__text{
      padding-top: $margin_sm;
      width: 100%;
      .c-ttl_pl{
        text-align: center;
      }
    }
  }
  @include mq(xs){
    padding: $margin_sm*2 0;
  }
  @include mq(ss){
    &__img{
      width: 100%;
    }
  }
}
/*-------------------------------
  INDEX > ORGANIZATION
-------------------------------*/
.p-idx_organization{
  padding: $margin_md*3 0;
  overflow: hidden;
  .c-org_list{
    position: relative;
    li{
      position: relative;
      z-index: 2;
    }
    @include mq(sm-){
      &:before{
        position: absolute;
        content:"";
        right: $margin_md*4;
        top: 50%;
        width: 100vw;
        height: 100vh;
        background: #f7f7f7;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md*2 0;
    background: #f7f7f7;
  }
  @include mq(xs){
    padding: $margin_sm*2 0;
  }
}
/*-------------------------------
  INDEX > INFORMATION
-------------------------------*/
.p-idx_information{
  padding: $margin_md*3 0;
  .c-inner{
    justify-content: space-between;
    align-items: stretch;
  }
  &__sec{
    width: 48%;
  }
  &__title{
    line-height: 1;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: $margin_ss*2;
    .c-ttl_sup{
      margin-bottom: 0;
    }
  }
  @include mq(sm){
    padding: $margin_md*2 0;
    .c-inner{
      display: block;
    }
    &__sec{
      width: 100%;
      &.-event{
        margin-top: $margin_md*2;
      }
    }
  }
  @include mq(xs){
    padding: $margin_sm*2 0;
    &__sec{
      &.-event{
        margin-top: $margin_sm*2;
      }
    }
  }
}
/*-------------------------------
  INDEX > COLUMN
-------------------------------*/
.p-idx_column{
  padding-bottom: $margin_md*4;
  overflow: hidden;
  &__head{
    margin-bottom: $margin_ss*2;
    .c-inner{
      justify-content: space-between;
      align-items: flex-end;
    }
    .c-ttl_sup{
      margin-bottom: 0;
    }
  }
  &__body{
    position: relative;
    .c-inner{
      position: relative;
      z-index: 2;
    }
    @include mq(sm-){
      &:before{
        position: absolute;
        content:"";
        left: 0;
        top: 50%;
        width: calc(100% - 12rem);
        height: 100vh;
        background: #f5f5f5;
        z-index: 1;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md*2 0;
    background: #f5f5f5;
  }
  @include mq(xs){
    padding: $margin_sm*2 0;
  }
}