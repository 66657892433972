@charset "utf-8";
/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header{
  $amp :&;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  .c-inner{
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &__toggle{
    position: relative;
    z-index: 102;
    width: 5rem;
    height: 5rem;
    .so_toggle{
      position: absolute;
      background: transparent;
      width: 72%;
      height: 72%;
      padding:0;
      left:50%;
      top:50%;
      transform:translate(-50%,-50%);
      .hamburger-icon{
        background: transparent;
        top:0;
        &:before,&:after{
          background: $font_color;
          top:0;
        }
        &:before{
          transform:translateY(-4px);
        }
        &:after{
          transform:translateY(4px);
        }
      }
    }
  }
  .slideout-open & {
    &__toggle{
      background: rgba(255,255,255,0);
      .so_toggle{
        .hamburger-icon{
          &:before,&:after{
            background: #fff;
          }
          &:before{
            transform:rotate(45deg);
          }
          &:after{
            transform:rotate(-45deg);
          }
        }
      }
    }
  }
  @include mq(sm-){
    &__left{
      background: #fff;
      padding: $margin_sm $margin_md $margin_sm $margin_sm;
      align-items: center;
    }
    &__logo{
      margin-right: $margin_md;
      a{
        img{
          height: 9.9rem;
        }
        &:hover{
          opacity: .5;
        }
      }
    }
    &__menu_sub{
      @include mq(w1300-){
        padding-right: $margin_sm;
      }
      li{
        font-size: .875em;
        &:nth-child(n+2){
          margin-left: .675em;
        }
        a{
          background: $font_color;
          color: #fff;
          padding:.5em .715em .45em;
          border:solid 1px $font_color;
          &:hover{
            background: #fff;
            color:$font_color;
          }
        }
      }
    }
    @include mq(w1600){
      &__left{
        padding: $margin_sm;
      }
      &__logo{
        margin-right: $margin_sm;
      }
    }
    @include mq(md){
      &__left{
        padding: $margin_ss;
      }
      &__logo{
        margin-right: $margin_ss;
        a{
          img{
            height: 7.7rem;
          }
        }
      }
    }
  }
  @include mq(w1300){
    &__left{
      padding: 2rem 1em;
    }
    &__menu{
      &_sub{
        li{
          a{
            padding: 0.5em 0.5em 0.45em;
          }
          &:nth-child(n+2){
            margin-left: 0.2em;
          }
        }
      }
    }
  }
  @include mq(sm){
    background: #fff;
    padding: $margin_ss $margin_ss/2;
    &__logo{
      a{
        img{
          height: $margin_md;
        }
      }
    }
  }
  @include mq(xs){
    &__logo{
      a{
        img{
          height: $margin_md;
        }
      }
    }
  }
}