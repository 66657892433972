@charset "utf-8";
/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer{
  position: relative;
  line-height: 1;
  @include mq(sm-){
    &:before{
      position: absolute;
      content:"";
      left: 0;
      top: 0;
      width: calc(100% - 12rem);
      height: 100%;
      background: #f5f5f5;
    }
    .-under &{
      &:before{
        display: none;
      }
    }
  }
  .c-inner{
    position: relative;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;
  }
  &__info{
    background: $font_color;
    color: #fff;
    padding: $margin_md;
  }
  &__title{
    font-size: 1.5em;
    line-height: 1;
  }
  &__copy{
    font-size: .875em;
    line-height: 1;
    margin-top: 1.5em;
  }
  &__totop{
    a{
      width: 12rem;
      padding: $margin_ss 0;
      display: block;
      background: $font_color;
      color: #fff;
      text-align: center;
      font-size: .75em;
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
      span{
        position: relative;
        padding: 1em 0;
        display: block;
        &:before,
        &:after{
          position: absolute;
          content:"";
          top:0 ;
          width: .5em;
          height: 1px;
          background: #fff;
          transition:$transition_val;
        }
        &:before{
          right:50%;
          transform-origin: right top;
          transform:rotate(-30deg);
        }
        &:after{
          left:50%;
          transform-origin: left top;
          transform:rotate(30deg);
        }
      }
    }
  }
  @include mq(sm){
    background: $font_color;
    .c-inner{
      display: block;
    }
    &__info{
      text-align: center;
      padding: $margin_sm 0 $margin_ss;
    }
    &__title{
      font-size: 1.25em;
    }
    &__copy{
      font-size: .75em;
    }
    &__totop{
      a{
        width: 100%;
        padding: 0 0 $margin_sm;
        span{
          padding-bottom: 0;
        }
      }
    }
  }
}