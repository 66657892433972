@charset "utf-8";
/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi{
  @include mq(sm-){
    // slideout 打ち消し
    position: static;
    background: transparent;
    width: auto;
    overflow: hidden;
    &__menu{
      line-height: 1;
      li{
        a{
          position: relative;
          display: block;
          padding: .3em .675em .25em;
          &:hover{
            background: $font_color;
            color: #fff;
          }
        }
      }
    }
    @include mq(w1600){
      &__menu{
        li{
          font-size: .875em;
        }
      }
    }
    @include mq(md){
      &__menu{
        li{
          font-size: 1em;
        }
      }
    }
  }
  @include mq(sm){
    background: $font_color;
    padding: $margin_ll $margin_sm $margin_sm;
    right: 0;
    transform:translateX(100%);
    width: 35%;
    &__menu{
      display: block;
      li{
        &:nth-child(n+2){
          margin-top: 1em;
        }
        a{
          color: #fff;
          padding: 0;
        }
      }
      &.-bottom{
        margin-top: 2em;
        li{
          font-size: .875em;
          a{
            display: block;
            text-align: center;
            background: #fff;
            color:$font_color;
            padding: .5em;
          }
        }
      }
    }
    .slideout-open &{
      transform:translateX(0);
    }
  }
  @include mq(xs){
    width: 40%;
  }
  @include mq(ss){
    padding: $margin_ll $margin_sm;
    width: 100%;
    font-size: 1.8rem;
  }
}