@charset "utf-8";

/*============================================

  OTHER PAGE

============================================*/
/*-------------------------------
  BASE > ABOUT
-------------------------------*/
.p-base_about {
  margin-bottom: $margin_md*2;

  @include mq(sm) {
    margin-bottom: $margin_sm*2;
  }
}

.p-base_about_top {
  justify-content: space-between;
  margin-bottom: $margin_md;

  @include mq(sm) {
    display: block;
    margin-bottom: $margin_sm;

    @include mq(xs-) {
      &__img {
        float: left;
      }

      &__text {
        width: 100%;

        >p {
          &:nth-child(2) {
            float: right;
            width: 57%;
            margin-bottom: 0;
          }

          &:nth-child(3) {
            clear: both;
            padding-top: 1em;
          }
        }
      }
    }
  }

  @include mq(xs) {
    &__img {
      width: 100%;
      margin-bottom: $margin_sm;
    }

    &__text {
      width: 100%;

      .sub_box {
        .image {
          img {
            width: 11rem;
          }
        }

        .text {
          max-width: calc(95% - 11rem);

          h3 {
            font-size: 1.3em;
          }
        }
      }
    }
  }

  @include mq(ss) {
    &__text {
      .sub_box {
        display: block;
        padding: $margin_ss;

        .image {
          margin-bottom: 1em;
          text-align: center;

          img {
            width: auto;
            height: 8rem;
          }
        }

        .text {
          max-width: 100%;

          h3 {
            text-align: center;
          }
        }
      }
    }
  }

  &__img {
    width: 40%;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__text {
    width: 55%;

    >p {
      margin-bottom: 1em;
    }

    .sub_box {
      justify-content: space-between;
      margin-top: $margin_sm;
      padding: $margin_sm;
      background: #f5f5f5;

      .image {
        img {
          max-width: 100%;
          height: auto;
        }
      }

      .text {
        max-width: calc(95% - 117px);
        font-size: .875em;

        h3 {
          margin-bottom: .5em;
          font-size: 1.5em;
        }
      }
    }
  }
}

.p-base_about_bottom {
  @include mq(sm) {
    &__text {
      padding: $margin_sm;
    }
  }

  @include mq(ss) {
    &__text {
      padding: $margin_sm;

      h3 {
        margin-bottom: 1em;
        font-size: 1.3em;
        text-align: center;
      }
    }
  }

  >p {
    margin-bottom: $margin_sm;
  }

  &__text {
    padding: $margin_md;
    background: #f5f5f5;

    h3 {
      margin-bottom: .75em;
      font-size: 1.5em;
    }

    p {
      line-height: 1.6;

      a {
        text-decoration: underline;
        word-break: break-all;

        @include mq(sm-) {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

/*-------------------------------
  BASE > ORGANIZATION
-------------------------------*/
.p-base_organization {
  &__img {
    img {
      max-width: 100%;
    }
  }
}

/*-------------------------------
  CURRICULUM
-------------------------------*/
.p-curriculum_main {
  .c-btnwrap {
    width: 100%;
    max-width: 920px;
    margin-top: 3rem;

    @include mq(sm) {
      width: 92%;
      max-width: 100%;

      a {
        width: 30%;
        min-width: 32.5%;
        padding: 1.7em 1em;
      }
    }

    @include mq(xs) {
      display: block;

      a {
        width: 100%;

        &:nth-child(n+2) {
          margin-top: 1em;
        }
      }
    }

    span {
      display: block;
      margin-bottom: .3em;
      font-size: 1.4rem;
    }
  }
}

.p-curriculum_form {
  margin-top: $margin_md;
}

/*-------------------------------
  WORK SHOP
-------------------------------*/
.p-workshop_main {
  @include mq(xs) {
    .c-tbl {
      table {
        tr:first-child {
          th:first-child {
            width: 35%;
          }
        }

        th {
          width: 65%;
        }
      }
    }
  }

  .c-page_lead {
    margin-bottom: 5rem;
  }

  .c-tbl {
    table {
      tr:first-child {
        th:first-child {
          width: 30%;
        }
      }

      th {
        width: 70%;
      }

      td {
        span {
          color: #cc1111;
        }
      }
    }
  }
}

/*-------------------------------
  LECTURE
-------------------------------*/
.p-lecture_main {
  .c-page_lead {
    margin-bottom: 10rem;

    gap: 30px;

    @media screen and (min-width: 991px) {
      padding-right: 80px;
      padding-left: 80px;

      gap: 80px;
    }

    figure {
      margin-bottom: 30px;
    }

    img {
      transition: .6s;
    }

    a {
      overflow: hidden;
      text-decoration: none;

      &:hover {
        img {
          scale: 1.1;
        }

        figcaption {
          background-color: #ffffff;
          color: #000000;
        }
      }
    }

    .c-page_lead__img {
      width: 100%;
      overflow: hidden;
    }

    figcaption {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-right: 1em;
      padding-bottom: 10px;
      padding-left: 1em;
      border: 1px solid #000000;
      border-top: none;
      background-color: #000000;
      color: #ffffff;
      text-align: center;
      transition: .6s;

      text-wrap: balance;
    }

    p {
      a {
        text-decoration: underline;

        @include mq(sm-) {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

/*-------------------------------
  量子情報理論への招待
-------------------------------*/
.p-invitation_wrap {
  margin-bottom: 5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-invitation_wrap {
  &__box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
    padding-bottom: .5em;
    border-bottom: 1px solid #dddddd;

    @include mq(xs) {
      align-items: stretch;
    }

    p {
      position: relative;
      width: 62%;
      padding-left: 1em;

      @include mq(xs) {
        width: 100%;
      }

      &:before {
        display: block;
        position: absolute;
        top: .6em;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #000000;
        content: "";
        ;
      }
    }
  }

  &__test {
    width: 18%;

    @include mq(xs) {
      width: 48%;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 1em;
      border: 1px solid #000000;
      background: #000000;
      color: #ffffff;
      font-size: .9em;
      line-height: 1;
      text-align: center;

      @include mq(sm-) {
        &:hover {
          background: #ffffff;
          color: #000000;
        }
      }

      @include mq(xs) {
        line-height: 1.3;
      }
    }
  }
}

/*-------------------------------
  公開のオンライン講座
-------------------------------*/

/*-------------------------------
  量子通信の基礎
-------------------------------*/
.p-basic {
  @include mq(xs) {
    li {
      padding: 1em;
      text-align: center;

      p {
        margin-bottom: .5em;
        line-height: 1.3;
      }
    }
  }

  &_list {
    @include mq(sm-) {
      width: 80%;
    }

    li {
      padding: .5em;
      border-bottom: 1px solid #dddddd;
      line-height: 1;

      @include mq(xs-) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      p {
        font-weight: 600;
        font-size: 1.1em;
      }

      a {
        display: inline-block;
        padding: .5em;
        border: 1px solid #000000;
        background: #000000;
        color: #ffffff;
        font-size: .9em;

        @include mq(sm-) {
          &:hover {
            background: #ffffff;
            color: #000000;
          }
        }
      }
    }
  }
}

/*-------------------------------
  PRIVACY > MAIN
-------------------------------*/
.p-privacy_policy {
  &__text {
    margin-bottom: $margin_md;
  }

  &__sec {
    &:not(:last-child) {
      margin-bottom: $margin_md;
    }

    .c-ttl_pl {
      margin-bottom: 0;
      line-height: 1.4;
    }

    p {
      margin-top: 1em;

      +.c-list {
        margin-top: .5em;
      }
    }
  }
}

/*-------------------------------
  NEWS > SINGLE
-------------------------------*/
.p-news_single {
  &__head {
    margin-bottom: 1.25em;
    padding-bottom: 1em;
    border-bottom: solid 1px $font_color;
    font-size: 3rem;

    time {
      display: block;
      margin-bottom: 1em;
      font-size: .5em;
      line-height: 1;
    }

    .title {
      line-height: 1.4;
    }
  }
}

/*-------------------------------
  EVENT > SINGLE
-------------------------------*/
.p-event_single {
  &__head {
    margin-bottom: 1.25em;
    padding-bottom: 1em;
    border-bottom: solid 1px $font_color;
    font-size: 3rem;

    time {
      display: block;
      margin-bottom: 1em;
      font-size: .5em;
      line-height: 1;
    }

    .title {
      line-height: 1.4;
    }
  }
}

/*-------------------------------
  COLUMN > SINGLE
-------------------------------*/
.p-column_single {
  &__head {
    margin-bottom: 1.25em;
    padding-bottom: 1em;
    border-bottom: solid 1px $font_color;
    font-size: 3rem;

    .info {
      align-items: center;
      margin-bottom: 1em;
      font-size: .5em;
      line-height: 1;

      .term {
        margin-left: 1em;
        padding: .35em .75em;
        background: $font_color;
        color: #ffffff;
      }
    }

    .title {
      line-height: 1.4;
    }
  }
}

/*-------------------------------
  INTERN
-------------------------------*/
.p-intern_guideline {
  margin-top: $margin_md*2;

  @include mq(sm) {
    margin-top: $margin_sm*2;
  }

  @include mq(xs-) {
    &__sheet {
      tr {
        th {
          width: 25%;
        }

        th,
        td {
          padding: 1.5em;
        }

        &:not(:first-child) {
          th {
            border-top-color: #ffffff;
          }
        }

        &:not(:last-child) {
          th {
            border-bottom-color: #ffffff;
          }
        }
      }
    }
  }

  @include mq(xs) {
    .c-tbl {
      tr {
        display: block;

        th,
        td {
          display: block;
          width: 100%;
          padding: 1.5em;
        }

        th {
          padding: 1em 1.5em;
        }

        td {
          padding: 1.75em 1.5em;
        }
      }
    }
  }

  &__sheet {
    margin-bottom: $margin_md;
  }
}

.p-intern_archive {
  margin-top: $margin_md*2;

  @include mq(sm) {
    margin-bottom: $margin_sm*2;
  }

  @include mq(xs) {
    .c-tbl {
      thead {
        display: none;
      }

      tr {
        display: block;
        margin-top: -1px;
        border: solid 1px $font_color;
      }

      td {
        display: block;
        border: none;

        &:first-child {
          width: 100%;
          margin-bottom: 1em;
          padding: .5em 1em;
          background: $font_color;
          color: #ffffff;
          font-weight: 700;
          font-size: 1.125em;
          text-align: left;
        }

        &:nth-child(n+2) {
          position: relative;
          padding: 0 1.125em 0 2.125em;
          padding-left: 7em;

          &:before {
            position: absolute;
            top: 0;
            left: 1em;
            font-weight: 700;
            content: attr(data-ttl) " / ";
          }
        }

        &:nth-child(n+3) {
          margin-top: .5em;
        }

        &:last-child {
          padding-bottom: 1.125em;
        }
      }
    }
  }
}

/*-------------------------------
  APPLICATION
-------------------------------*/
.p-app_form {
  [data-input="age"] {
    width: 5em;
  }
}

/*-------------------------------
  NOT FOUND
-------------------------------*/
.p-notfound_main {
  .c-ttl_sup {
    @include mq(sm-) {
      margin-bottom: 1.25em;
    }
  }

  .c-btnwrap {
    margin-top: $margin_md;
    text-align: center;
  }
}