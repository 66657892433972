@charset "utf-8";

/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: $base_width;
  max-width: $base_width_max;
  margin-right: auto;
  margin-left: auto;

  &.-full {
    width: $base_width_max;
  }
}

.c-key_under {
  position: relative;

  @include mq(sm) {
    &__img {
      &.c-img_ofi {
        padding-top: 30rem;
      }
    }

    &__text {
      .title {
        padding: 1em 0;
        font-size: 3rem;
      }
    }
  }

  @include mq(xs) {}

  @include mq(ss) {
    &__img {
      &.c-img_ofi {
        padding-top: 25rem;
      }
    }
  }

  &__img {
    &.c-img_ofi {
      padding-top: 35rem;
    }
  }

  &__text {
    background: $font_color;
    color: #ffffff;

    .title {
      padding: 1.275em 0;
      font-weight: 500;
      font-size: 3.6rem;

      @include mq(ss-) {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          position: relative;
          margin-top: 0;
          margin-left: 2em;
          padding-left: 3em;

          &:after {
            position: absolute;
            bottom: .5em;
            left: 0;
            width: 2em;
            height: 1px;
            background: #ffffff;
            content: "";
          }
        }
      }

      span {
        display: block;
        margin-top: .75em;
        font-size: .5em;

        &:before {
          content: "カテゴリ：";
        }
      }
    }
  }
}

.c-page_lead {
  $amp: &;

  @include mq(ss-) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__img {
      width: 40%;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    &__text {
      width: 55%;

      &.-mb {
        p {
          margin-bottom: 1.5em;
        }
      }
    }
  }

  @include mq(ss) {
    &__text {
      margin-top: $margin_ss;
    }
  }

  a {
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__img {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &.-en {
    .c-btnwrap {
      margin-bottom: 1.5em;
      margin-left: 0;
    }
  }
}

/*--------------------------------------------
  LISt
--------------------------------------------*/
.c-list {
  &.-dot {
    padding-left: 1.5em;

    li {
      list-style: disc;
    }
  }

  li {
    line-height: 1.5;

    &:nth-child(n+2) {
      margin-top: .5em;
    }
  }
}

/*--------------------------------------------
  CARD
--------------------------------------------*/
.c-card {
  $amp: &;

  @include mq(xs-) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }

  li {
    @include mq(xs-) {
      width: 30%;
      margin-right: 5%;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(n+4) {
        margin-top: 2em;
      }
    }

    @include mq(xs) {
      &:nth-child(n+2) {
        margin-top: 2em;
      }
    }

    em {
      font-style: normal;
      font-weight: 600;
      font-size: 1.2em;
    }
  }

  &_img {
    margin-bottom: 1em;
    overflow: hidden;
    border: 1px solid #dddddd;

    @include mq(xs) {
      margin-bottom: .5em;
    }

    img {
      width: 100%;
      transition-duration: .3s;
    }
  }

  a {
    display: block;

    @include mq(sm-) {
      &:hover {
        #{$amp}_img {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-ttl_pl {
  margin-bottom: 1.4em;
  font-size: 3rem;

  @include mq(sm) {
    margin-bottom: 1em;
    font-size: 2.6rem;
  }
}

.c-ttl_sup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 1.7em;
  font-size: 3rem;
  line-height: 1;

  @include mq(sm) {
    margin-bottom: 1.25em;
    font-size: 2.6rem;
  }

  @include mq(ss) {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 2.4rem;

    >* {
      width: 100%;
    }

    .ttl {
      padding-left: 0;

      &:before,
      &:after {
        display: none;
      }
    }

    .sup {
      margin-bottom: 1em;
      margin-left: 0;
      padding-left: 3em;

      &:before {
        top: 50%;
        transform: rotate(0);
        width: 2em;
        height: 1px;
        background: $font_color;
        content: "";
      }

      &:after {
        position: absolute;
        top: calc(50% + 1px);
        left: 0;
        transform: translateY(-50%);
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $font_color;
        content: "";
      }
    }

    &.-symbol {
      &:before {
        display: none;
      }

      .ttl {
        padding-left: 0;
      }

      .sup {
        padding-top: 1em;
        padding-left: 2.5em;

        &:before {
          display: none;
        }

        &:after {
          top: initial;
          bottom: 0;
          transform: translateY(0%);
          width: 2em;
          height: 2em;
          border-radius: 0;
          background: #f5f5f5;
        }
      }
    }
  }

  .ttl {
    position: relative;
    padding-left: 2.5em;

    &:before {
      position: absolute;
      bottom: 50%;
      left: 0;
      transform: translateY(50%);
      width: .35em;
      height: .35em;
      border-radius: 50%;
      background: $font_color;
      content: "";
    }

    &:after {
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 1.35em;
      height: 1px;
      background: $font_color;
      content: "";
    }
  }

  .sup {
    position: relative;
    margin-left: 1em;
    padding-left: 1em;
    font-size: .5em;

    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(35deg);
      width: 1px;
      height: 100%;
      background: $font_color;
      content: "";
    }
  }

  &.-symbol {
    position: relative;
    padding-top: .5em;

    .ttl {
      z-index: 2;
      padding-left: .5em;

      &:before,
      &:after {
        display: none;
      }
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 1em;
      height: 1em;
      background: #f5f5f5;
      content: "";
    }
  }
}

.c-ttl_v {
  position: relative;
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 1;

  @include mq(sm-) {
    padding-left: .555em;
    border-left: solid 1px $font_color;

    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;

    &:after {
      top: 100%;
      left: -6px;
    }
  }

  @include mq(sm) {
    padding-right: 11px;
    padding-bottom: .555em;
    border-bottom: solid 1px $font_color;

    &:after {
      right: 0;
      bottom: -6px;
    }
  }

  &:after {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: $font_color;
    content: "";
  }
}

.c-ttl_line {
  margin-bottom: 2em;
  font-size: 1.875em;
  line-height: 1.3;

  @include mq(sm) {
    margin-bottom: 1em;
    font-size: 1.5em;

    span {
      &:after {
        bottom: -3px;
        width: 7px;
        height: 7px;
      }
    }
  }

  span {
    display: inline-block;
    position: relative;
    padding-right: calc(.5em + 11px);
    padding-bottom: .5em;
    border-bottom: solid 1px $font_color;

    &:after {
      position: absolute;
      right: 0;
      bottom: -5px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: $font_color;
      content: "";
    }
  }

  p {
    margin-top: .5em;
    font-size: .6em;
  }

  &.-flex {
    @include mq(sm-) {
      display: flex;

      span {
        width: 80%;
      }
    }

    p {
      width: 16rem;

      @include mq(sm-) {
        margin-left: 1em;
      }

      a {
        display: block;

        @include mq(sm-) {
          &:hover {
            opacity: .6;
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.c-ttl_wt {
  margin-bottom: .5em;
  font-weight: 600;
  font-size: 1.2em;
}

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  @include mq(xs) {
    a {
      &:nth-child(n+2) {
        margin-top: 1em;
      }
    }
  }

  &:not(:first-child) {
    margin-top: 2em;
  }

  &.-fx {
    display: flex;
    justify-content: center;
    align-items: center;

    &.-justify {
      justify-content: space-between;
    }

    &.-right {
      justify-content: flex-end;
    }
  }

  &.-center {
    text-align: center;
  }

  &.-right {
    text-align: right;
  }
}

.c-btn_arr {
  display: inline-block;
  position: relative;
  min-width: 300px;
  padding: 1.7em 2em;
  border: solid 1px $font_color;
  background: $font_color;
  color: #ffffff;
  font-size: .875em;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  transition: $transition_val;

  @include mq(sm-) {
    &:hover {
      background: #ffffff;
      color: $font_color;

      &:before,
      &:after {
        background: $font_color;
      }
    }
  }

  &:before,
  &:after {
    position: absolute;
    right: 2em;
    width: 1px;
    height: .4em;
    background: #ffffff;
    content: "";
    transition: $transition_val;
  }

  &:before {
    bottom: 50%;
    transform: rotate(-30deg);

    transform-origin: right bottom;
  }

  &:after {
    top: 50%;
    transform: rotate(30deg);

    transform-origin: right top;
  }
}

.c-btn_arow {
  display: inline-block;
  position: relative;
  padding-top: .6em;
  padding-right: 3.5em;
  padding-bottom: .6em;
  padding-left: 3.5em;
  border-color: #27acd9;
  background-color: #27acd9;
  color: #ffffff;
  font-size: .9em;

  @include mq(sm-) {
    &:hover {
      opacity: .6;

      &:before {
        // right: -.7em;
      }
    }
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 14px;
    width: 20px;
    height: 20px;
    margin: auto;
    border-radius: 50%;
    background: #ffffff;
    content: "";
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 17px;
    box-sizing: border-box;
    color: #27acd9;
    font-size: 12px;
    content: "▲";
    transition: .3s;

    translate: 0 -50%;
    rotate: -30deg;
    -webkit-box-sizing: border-box;
    -webkit-transition: .3s;
  }
}

.blue {
  color: #27acd9;
}

.c-read {
  p {
    margin-bottom: 1em;
  }

  &:not(:first-child) {
    margin-top: 4em;
  }
}

.c-link_arr {
  position: relative;
  font-size: .875em;
  line-height: 1;
  text-align: right;
  transition: $transition_val;

  a {
    padding-right: 2.5em;

    @include mq(sm-) {
      &:hover {
        opacity: .5;
        padding-right: 1.5em;
      }
    }

    &:after {
      position: absolute;
      top: .15em;
      right: 0;
      transform: skew(45deg);
      width: 1.285em;
      height: .35em;
      border-right: solid 1px $font_color;
      border-bottom: solid 1px $font_color;
      content: "";
    }
  }
}

.c-annotation {
  padding: 1.5em;
  border: 1px solid #dddddd;

  &:not(:first-child) {
    margin-top: 3em;
  }
}

/*--------------------------------------------
  TABLE
--------------------------------------------*/
.c-tbl {
  @include mq(xs) {
    tr {

      th,
      td {
        padding: .5em;
      }

      td {}
    }
  }

  tr {

    th,
    td {
      padding: 1em;
      border: 1px solid #000000;
    }

    th {
      background: #000000;
      color: #ffffff;
    }

    td {
      a {
        display: inline-block;
        text-decoration: underline;

        @include mq(sm-) {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.c-tbl_responsive {
  @include mq(sm) {
    &__inner {
      overflow-x: auto;
      overflow-y: visible;

      table {
        width: auto;
        min-width: 100%;

        th,
        td {
          white-space: nowrap;
        }
      }

      &::-webkit-scrollbar {
        height: .6rem;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: $main_color;
      }
    }

    &:after {
      display: block;
      margin-top: .5em;
      text-align: center;
      content: "横にスクロールできます";
    }
  }
}

/*--------------------------------------------
 PARTS - ORGANIZATION
--------------------------------------------*/
.c-org_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  $amp: &;

  @include mq(sm) {
    @include mq(xs-) {
      justify-content: center;

      &__img {
        padding-top: 75%;
      }

      li {
        width: 31%;
        margin-right: 3.5%;

        &:nth-child(3n),
        &:last-child {
          margin-right: 0;
        }

        &:nth-child(n+4) {
          margin-top: 3.5%;
        }
      }
    }
  }

  @include mq(xs) {
    li {
      width: 48%;

      &:nth-child(n+3) {
        margin-top: $margin_ss;
      }
    }

    &__img {
      padding-top: 75%;

      img {
        max-width: 80%;
        max-height: 60%;
      }
    }

    &__text {
      h3 {
        span {
          width: 100%;
          border-right: 0;
        }

        i {
          display: none;
        }
      }
    }
  }

  li {
    width: 19%;
    background: #ffffff;

    a {
      display: block;
      border: solid 1px $font_color;

      @include mq(sm-) {
        &:hover {
          opacity: .5;
        }
      }
    }
  }

  &__img {
    position: relative;
    width: 100%;
    padding-top: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 75%;
      height: auto;
    }
  }

  &__text {
    background: $font_color;
    color: #ffffff;

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      span {
        display: inline-block;
        width: 80%;
        padding: .75em 1.25em .65em;
        border-right: solid 1px #6c6c6c;
      }

      i {
        display: inline-block;
        position: relative;
        width: 20%;
        height: 100%;

        &:before,
        &:after {
          position: absolute;
          left: 50%;
          width: 1px;
          height: .4em;
          background: #ffffff;
          content: "";
        }

        &:before {
          bottom: 50%;
          transform: rotate(-30deg);

          transform-origin: right bottom;
        }

        &:after {
          top: 50%;
          transform: rotate(30deg);

          transform-origin: right top;
        }
      }
    }
  }

  &.-en {
    align-items: stretch;

    li {
      overflow: hidden;

      a {
        display: block;
        height: 100%;
      }
    }

    #{$amp}__text {
      height: 100%;
    }
  }
}

.c-link_list {
  @include mq(xs-) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }

  li {
    list-style-type: none;
    position: relative;
    border-bottom: #dddddd 1px solid;
    line-height: 1.4;

    @include mq(xs-) {
      width: 48%;
    }

    @include mq(sm-) {
      &:before {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: #000000;
        content: "";
        transition-duration: .3s;
      }

      &:hover {
        &:before {
          width: 100%;
        }
      }
    }
  }

  a {
    display: block;
    position: relative;
    height: 100%;
    padding: 1em .1em 1em 2.5em;
    font-weight: 600;

    &:before {
      position: absolute;
      bottom: 50%;
      left: 0;
      transform: translateY(50%);
      width: .35em;
      height: .35em;
      border-radius: 50%;
      background: $font_color;
      content: "";
    }

    &:after {
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 1.35em;
      height: 1px;
      background: $font_color;
      content: "";
    }
  }
}

/*--------------------------------------------
 PARTS - NEWS & EVENT
--------------------------------------------*/
.c-post_list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;

  @include mq(xs) {
    li {
      margin-right: .5em;
    }

    a {
      font-size: 1.4rem;
    }
  }

  li {
    margin-right: 1em;
    margin-bottom: .5em;
    line-height: 1;
  }

  a {
    display: inline-block;
    padding: .8em 1em;
    border: 1px solid #000000;
    background: #000000;
    color: #ffffff;
    font-size: 1.6rem;

    @include mq(sm-) {
      &:hover {
        background: #ffffff;
        color: #000000;
      }
    }
  }
}

.c-post_information {
  @include mq(xs-) {
    &.-idx {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      li {
        width: 48%;
        margin-right: 0;
        border-top: solid 1px $font_color;
      }
    }
  }

  @include mq(ss) {
    &.-idx {
      li {
        &:first-child {
          border-top: solid 1px $font_color;
        }
      }
    }
  }

  li {
    width: 100%;
    border-bottom: solid 1px #e6e6e6;

    &:first-child {
      border-top: solid 1px #e6e6e6;
    }

    a {
      display: block;
      position: relative;
      padding: 1.875em 0 1.45em;

      @include mq(sm-) {
        &:hover {
          &:before {
            height: 100%;
          }
        }
      }

      >* {
        position: relative;
        z-index: 2;
      }

      time {
        display: block;
        margin-bottom: 1em;
        font-size: .875em;
        line-height: 1;
      }

      .title {
        line-height: 1.875;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 0;
        background: #f5f5f5;
        content: "";
        transition: $transition_val;
      }
    }
  }
}

/*--------------------------------------------
 PARTS - COLUMN
--------------------------------------------*/
.c-term_column {
  margin-bottom: $margin_md;
  padding: $margin_sm;
  background: #f5f5f5;

  @include mq(ss) {
    padding: $margin_sm $margin_ss;

    &__list {
      li {
        padding-top: 0;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 1em;
        }

        a {
          &:before {
            border: solid 2px $font_color;
            background: transparent;
          }

          &:after {
            display: none;
          }

          &.is-act {
            &:before {
              background: $font_color;
            }
          }
        }
      }
    }
  }

  &__title {
    font-size: 1.25em;
  }

  &__list {
    margin-top: 1em;
    line-height: 1;

    @include mq(xs-) {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    li {
      padding-top: .5em;

      &:not(:last-child) {
        margin-right: 1.5em;
      }

      a {
        display: block;
        position: relative;
        padding-left: 1.55em;

        @include mq(sm-) {
          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }

        &:before,
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          content: "";
        }

        &:before {
          transform: scale(.4);
          background: $font_color;
        }

        &:after {
          opacity: 0;
          border: solid 2px $font_color;
          transition: $transition_val;
        }

        &.is-act {
          pointer-events: none;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.c-post_column {
  $amp: &;

  @include mq(xs-) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    li {
      width: 31%;
      margin-right: 3.5%;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(n+4) {
        margin-top: $margin_ss;
      }
    }
  }

  @include mq(xs) {
    @include mq(ss-) {
      li {
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &:nth-child(n+2) {
          margin-top: $margin_sm;
          padding-top: $margin_sm;
          border-top: solid 1px #e0e0e0;
        }
      }

      &__img {
        &.c-img_ofi {
          width: 40%;
          padding-top: 30%;
        }
      }

      &__text {
        width: 55%;
        padding-top: 0;
      }
    }
  }

  @include mq(ss) {
    li {
      &:nth-child(n+2) {
        margin-top: $margin_sm;
      }
    }
  }

  li {
    a {
      display: block;

      @include mq(sm-) {
        &:hover {
          #{$amp}__img {
            img {
              opacity: .5;
              transform: scale(1.1);
            }
          }

          #{$amp}__text {
            opacity: .5;
          }
        }
      }
    }
  }

  &__img {
    background: #ffffff;

    &.c-img_ofi {
      padding-top: 75%;
      overflow: hidden;
    }

    img {
      max-width: 100%;
      height: auto;
      transition: $transition_val;
    }
  }

  &__text {
    padding-top: 1.875em;
    transition: $transition_val;

    time {
      display: block;
      margin-bottom: 1em;
      font-size: .875em;
      line-height: 1;
    }

    .title {
      line-height: 1.875;
    }
  }
}

/*--------------------------------------------
  PARTS - FORM
--------------------------------------------*/
.c-form_box {
  $amp: &;

  &__sheet {
    margin-bottom: $margin_md;

    @include mq(xs) {
      tr {
        display: block;
        padding: 1.75em 0;
      }

      th,
      td {
        display: block;
        width: 100%;
        padding: 0;
      }

      th {
        margin-bottom: 1.5em;
        line-height: 1;
      }
    }

    tr {
      border-bottom: solid 1px #cccccc;

      &.-top {

        th,
        td {
          vertical-align: top;
        }

        #{$amp}__label {
          padding-top: .5em;
        }
      }
    }

    th,
    td {
      padding: 2em 1em;
      line-height: 1.6;
      text-align: left;
      vertical-align: middle;
    }

    th {
      width: 25%;
    }

    td {
      width: 85%;
    }
  }

  &__label {
    &.-require {
      &:after {
        margin-left: .5em;
        color: $font_color;
        color: #cc1111;
        content: "【必須】";

        @include mq(sm) {
          @include mq(xs-) {
            display: block;
            margin-left: 0;
            font-size: .875em;
          }
        }
      }
    }
  }

  &.en {
    #{$amp}__label {
      &.-require {
        &:after {
          content: "[Required]";
        }
      }
    }
  }

  &__error {
    .error {
      color: #cc1111;
    }
  }

  &__btn {
    @include mq(xs) {
      &.-fx {
        display: block;
        text-align: center;
      }

      >input {
        &.c-btn_arr {
          max-width: 70%;
        }
      }
    }

    >input {
      &.c-btn_arr {
        min-width: 1px;
        max-width: 32%;
        margin: 0 auto;
        padding: 1.7em 0;
        font-size: .875em;

        @include mq(sm) {
          padding: 1.5em 0;
        }

        &.-cancel {
          border-color: #aaaaaa;
          background: #aaaaaa;
          color: #ffffff;

          @include mq(sm-) {
            margin-left: $margin_sm;

            &:hover {
              opacity: .5;
            }
          }

          @include mq(xs) {
            margin-top: 1.5em;
          }
        }

        &:hover,
        &:focus {
          outline: none;
        }
      }
    }
  }

  &__agreement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $margin_sm;

    a {
      text-decoration: underline;

      @include mq(sm-) {
        &:hover {
          text-decoration: none;
        }
      }
    }

    #{$amp}__input {
      &.-check {
        margin-right: .75em;
        padding: 0;

        .mwform-checkbox-field {
          display: block;
          margin-top: 0;

          &:not(:last-child) {
            margin-right: 0;
          }
        }

        input[type="checkbox"] {
          +.mwform-checkbox-field-text {
            padding-left: 1.75em;
            font-size: 0;
          }
        }
      }
    }
  }

  &__input {
    @include mq(sm-) {
      &.-short {
        .text_field {
          width: 40%;
        }
      }

      &.-middle {
        .text_field {
          width: 60%;
        }
      }

      &.-long {
        .text_field {
          width: 80%;
        }
      }
    }

    textarea {
      height: 12em;
    }

    &.-radio {
      padding: .5em 0;
      line-height: 1;

      @include mq(ss-) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
      }

      .mwform-radio-field {
        display: block;
        margin-top: 1em;

        &:not(:last-child) {
          margin-right: 2.5em;
        }

        +.mwform-radio-field {
          margin-left: 0;
        }
      }

      input[type="radio"] {
        display: none;

        +.mwform-radio-field-text {
          display: block;
          position: relative;
          min-width: 1.6rem;
          min-height: 1.6rem;
          padding-left: 2.4rem;

          &:before,
          &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
            content: "";
          }

          &:before {
            border: solid 1px #aaaaaa;
          }

          &:after {
            visibility: hidden;
            transform: scale(.35);
            background: $font_color;
          }
        }

        &:checked {
          +.mwform-radio-field-text {
            &:after {
              visibility: visible;
            }
          }
        }
      }
    }

    &.-check {
      padding: .5em 0;
      line-height: 1;

      @include mq(ss-) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
      }

      .mwform-checkbox-field {
        display: block;
        margin-top: 1.6rem;

        &:not(:last-child) {
          margin-right: 4rem;
        }

        ~.mwform-checkbox-field {
          margin-left: 0;
        }
      }

      input[type="checkbox"] {
        display: none;

        +.mwform-checkbox-field-text {
          display: block;
          position: relative;
          min-width: 1.6rem;
          min-height: 1.6rem;
          padding-left: 2.4rem;

          &:before,
          &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 1.6rem;
            height: 1.6rem;
            content: "";
          }

          &:before {
            border: solid 2px #e0e0e0;
          }

          &:after {
            visibility: hidden;
            transform: rotate(-45deg) translate(25%, 75%);
            width: 1.6rem;
            height: 1rem;
            border-bottom: solid 3px $font_color;
            border-left: solid 3px $font_color;

            transform-origin: left bottom;
          }
        }

        &:checked {
          +.mwform-checkbox-field-text {
            &:after {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  &.-confirm {
    #{$amp}__label {
      padding: 0;
    }

    #{$amp}__agreement {
      display: none;
    }

    #{$amp}__sheet {
      tr {
        &.-top {

          th,
          td {
            vertical-align: top;
          }

          #{$amp}__label {
            padding-top: .5em;
          }
        }
      }
    }
  }

  &.-thanks {
    @include mq(xs) {
      .c-ttl_pl {
        font-size: 2rem;
      }

      .c-btnwrap {
        margin-top: $margin_sm;
        text-align: center;
      }
    }

    .c-btnwrap {
      margin-top: $margin_md;
    }
  }
}


.c-form {
  $amp: &;

  @include mq(sm) {
    &__sheet {
      &:nth-child(n+2) {
        margin-top: $margin_md;
      }
    }
  }

  @include mq(xs) {
    &__sheet {
      tbody {
        tr {
          display: block;
          padding: 1.5em 0;

          th,
          td {
            display: block;
            width: 100%;
            padding: 0;
          }

          th {
            margin-bottom: .75em;
          }
        }
      }
    }

    &__ttl {
      &:before {
        margin-right: .25em;
        content: "■";
      }
    }

    &__row {
      margin-top: -.75em;

      >* {
        margin-top: .75em;
      }
    }
  }

  &__sheet {
    &:nth-child(n+2) {
      margin-top: $margin_lg;
    }

    thead {
      th {
        padding: 1em;
        background: $font_color;
        color: #ffffff;
        font-weight: 700;
        font-size: 1.25em;
        line-height: 1;
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px #cccccc;
      }

      th,
      td {
        padding: 1em;
        padding: 2em 1em;
        line-height: 1.6;
        text-align: left;
        vertical-align: middle;
      }

      th {
        width: 25%;
      }
    }
  }

  &__ttl {
    &.-required {
      &:after {
        margin-left: .5em;
        color: $font_color;
        color: #cc1111;
        content: "【必須】";

        @include mq(sm) {
          @include mq(xs-) {
            display: block;
            margin-left: 0;
            font-size: .875em;
          }
        }
      }
    }
  }

  &__input {
    >* {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    >* {
      &:nth-last-child(n+2) {
        margin-right: .75em;
      }
    }
  }

  &__notes {
    position: relative;
    margin-top: .75em;
    padding-left: 1.25em;
    font-size: .875em;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "※";
    }
  }

  &__error {
    .error {
      color: #cc1111;
    }
  }

  &__btn {
    margin-top: $margin_md;

    @include mq(xs) {
      &.-fx {
        display: block;
        text-align: center;
      }

      >input {
        &.c-btn_arr {
          max-width: 70%;
        }
      }
    }

    >input {
      &.c-btn_arr {
        min-width: 1px;
        max-width: 32%;
        margin: 0 auto;
        padding: 1.7em 0;
        font-size: .875em;

        @include mq(sm) {
          padding: 1.5em 0;
        }

        &.-cancel {
          border-color: #aaaaaa;
          background: #aaaaaa;
          color: #ffffff;

          @include mq(sm-) {
            margin-left: $margin_sm;

            &:hover {
              opacity: .5;
            }
          }

          @include mq(xs) {
            margin-top: 1.5em;
          }
        }

        &:hover,
        &:focus {
          outline: none;
        }
      }
    }
  }

  &.-input {
    @include mq(sm) {
      #{$amp}__field {
        &.-short {
          width: 40%;
        }

        &.-middle {
          width: 60%;
        }

        &.-long {
          width: 80%;
        }

        .text_field {
          &[size] {
            padding: .5em .75em;
          }

          &[size="0"],
          &:not([size]) {
            padding: .5em .75em;
          }
        }
      }
    }

    @include mq(xs) {
      #{$amp}__field {
        &.-short {
          width: 100%;
        }

        &.-middle {
          width: 100%;
        }

        &.-long {
          width: 100%;
        }

        .text_field {
          &[size] {
            padding: .65em .75em;
          }

          &[size="0"],
          &:not([size]) {
            padding: .65em .75em;
          }
        }

        .select_field {
          padding: .5em .75em;
        }
      }
    }

    #{$amp}__field {
      &.-short {
        width: 30%;
      }

      &.-middle {
        width: 50%;
      }

      &.-long {
        width: 70%;
      }

      &.-full {
        width: 100%;
      }

      &.-half {
        width: 48%;

        +& {
          margin-left: 4%;
        }
      }

      .text_field {
        display: block;

        &[size] {
          width: auto;
        }

        &[size="0"],
        &:not([size]) {
          width: 100%;
        }
      }

      .select_field {
        padding: .45em .75em .4em;
      }

      textarea {
        display: block;
        width: 100%;
        height: 12em;
      }
    }
  }

  &.-thanks {
    @include mq(xs) {
      .c-btnwrap {
        margin-top: $margin_sm;
        text-align: center;
      }
    }

    .c-btnwrap {
      margin-top: $margin_md;
    }
  }
}

/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;

  backface-visibility: hidden;

  .ofi {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;

    &.cover {
      font-family: "object-fit: cover;object-position:center center;";

      object-fit: cover;
      object-position: center center;
    }

    &.contain {
      margin: auto;
      font-family: "object-fit: contain;object-position:center center;";

      object-fit: contain;
    }
  }
}


/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto;
}

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.c-ta_left {
  text-align: left;
}

.c-ta_center {
  text-align: center;
}

.c-ta_right {
  text-align: right;
}

.c-ta_justify {
  @include mq(ss-) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-pe_none {
  pointer-events: none;
}


.c-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
}

@include mq(sm-) {
  .c-pe_sp {
    cursor: default;

    pointer-events: none;
  }
}

.c-text_mb {
  margin-bottom: 2em;
}



/*--------------------------------------------
  Navi
--------------------------------------------*/
.list_nav {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 3em;

  li {
    list-style-type: none;
    width: 300px;

    a {
      display: block;
      list-style: 1;
      padding: .7em;
      border: 1px solid #000000;
      background: #000000;
      color: #ffffff;
      text-align: center;

      @include mq(sm-) {
        &:hover {
          background: #ffffff;
          color: #000000;
        }
      }
    }
  }
}