@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?fsx2ee');
  src:  url('../fonts/icomoon.eot?fsx2ee#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?fsx2ee') format('truetype'),
    url('../fonts/icomoon.woff?fsx2ee') format('woff'),
    url('../fonts/icomoon.svg?fsx2ee#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon($type){
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @if $type == 'mail' {
    content: "\e91b";
  }
  @else if $type == 'phone' {
    content: "\e91c";
  }
  @else if $type == 'plus' {
    content: "\f067";
  }
  @else if $type == 'minus' {
    content: "\f068";
  }
  @else if $type == 'chevron-left' {
    content: "\f053";
  }
  @else if $type == 'chevron-right' {
    content: "\f054";
  }
  @else if $type == 'plus-circle' {
    content: "\f055";
  }
  @else if $type == 'minus-circle' {
    content: "\f056";
  }
  @else if $type == 'chevron-up' {
    content: "\f077";
  }
  @else if $type == 'chevron-down' {
    content: "\f078";
  }
  @else if $type == 'darrow-r' {
    content: "\f101";
  }
  @else if $type == 'darrow-u' {
    content: "\f102";
  }
  @else if $type == 'darrow-b' {
    content: "\f103";
  }
  @else if $type == 'arrow-l' {
    content: "\f104";
  }
  @else if $type == 'arrow-r' {
    content: "\f105";
  }
  @else if $type == 'arrow-u' {
    content: "\f106";
  }
  @else if $type == 'arrow-b' {
    content: "\f107";
  }

}
